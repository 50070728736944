import React, { useEffect, useState } from 'react'
import { SingleItemStyle } from './SingleItem.style'
import TrafficMap from '../../Components/TrafficMap/TrafficMap'
import { NavLink, useParams } from 'react-router-dom'
import JustMap from '../../Components/TrafficMap/JustMap'
import axios from 'axios'
import supabase from "../../supabase.js"
import { CreateSingleItemStyle } from './CreateSingleItem.style'

function CreateSingleItem() {
    const { id } = useParams()
    const [gps, setGps] = useState(true)
    const [generelt, setGenerelt] = useState(false)
    const [vejr, setVejr] = useState(false)
    const [Lat, setLat] = useState(56.12993473363111)
    const [Lng, setLng] = useState(8.897034010297991)
    const API_KEY = 'AIzaSyAfPOw-RMPwGU-Gt96Ur0OmVwxgNBbyC5Q';
    const [address, setAddress] = useState()
    const [weather, setWeather] = useState()
    const [alarm, setAlarm] = useState()
const currentHour = new Date().getHours()

console.log('current hour', currentHour);

function visibility(str) {
    let vis;

    switch (true) {
      case str > 10000:
        vis = "Fremragende";
        break;
      case str > 5000:
        vis = "Meget God";
        break;
      case str > 3000:
        vis = "God";
        break;
      case str > 1000:
        vis = "Moderat";
        break;
      case str > 500:
        vis = "Dårlig";
        break;
      case str > 200:
        vis = "Meget Dårlig";
        break;
      case str > 0:
        vis = "Elendig";
        break;
      default:
        vis = "Nul Synlighed";
    }
  
    return vis;
}
      useEffect(() => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${Lat},${Lng}&key=${API_KEY}`;
        const url2 = `https://api.open-meteo.com/v1/forecast?latitude=${Lat}&longitude=${Lng}&hourly=temperature_2m,precipitation,apparent_temperature,visibility,windspeed_10m,winddirection_10m,windgusts_10m,is_day&daily=uv_index_max&windspeed_unit=ms&timezone=Europe%2FBerlin&forecast_days=1`;
    
        axios.get(url)
          .then(response => {
            // Handle the response data here
            console.log(response.data);
            setAddress(response.data)
          })
          .catch(error => {
            // Handle errors here
            console.error(error);
          });

          // axios.get(url2)
          // .then(response => {
          //   // Handle the response data here
          //   console.log(response.data);
          //   setWeather(response.data)
          // })
          // .catch(error => {
          //   // Handle errors here
          //   console.error(error);
          // });
      }, [Lat, Lng]);
console.log(document.getElementById('mapLat'));
console.log(address ? address.results : 0);

async function newRecord() {
  const { data, error } = await supabase
    .from('echoAlarmer')
    .insert([
      { dato: document.getElementById('date').value ,
        tid: document.getElementById('time').value ,
        placering: `${document.getElementById('land').value} ${document.getElementById('street').value} ${document.getElementById('streetNum').value} ${document.getElementById('city').value}` ,
        lat: document.getElementById('inputLat').value ,
        long: document.getElementById('inputLong').value ,
        melding: document.getElementById('alarm').value ,
        station: document.getElementById('station').value ,
        noter: document.getElementById('notes').value ,
        varsler: document.getElementById('weatherWarning').value ,
        beskrivelse: document.getElementById('description').value },
    ])
    .select();
    if (error) {
      console.log('create error:', error);
    }else{
      alert('Alarmen er nu oprettet')
    }
}
  return (
    <CreateSingleItemStyle>

           <article><NavLink to={"/dashboard"}>{"<--"} Alarmer</NavLink>
            <h2>Ny alarm</h2>
            <p>Første melding:</p>
            <input type="date" name="date" id="date" />
            <input type="time" name="time" id="time" />
            <p></p>
            <div className='btnDiv'>
                <button className={gps ? 'active' : 'notActive'} onClick={() => {
                    setGps(true)
                    setGenerelt(false)
                    setVejr(false)
                }}>Sted</button>
                <button className={generelt ? 'active' : 'notActive'} onClick={() => {
                    setGps(false)
                    setGenerelt(true)
                    setVejr(false)
                }}>Generelt</button>
                <button className={vejr ? 'active' : 'notActive'} onClick={() => {
                    setGps(false)
                    setGenerelt(false)
                    setVejr(true)
                }}>Vejret</button>
            </div>
            
                <form action="">
                    
                <div className='gpsData' style={gps ? {display: 'block'} : {display: 'none'}}>
                <p>GPS data</p>
                <h2>Land</h2>
                <input type="text" value={address ? address.results[address.results.length - 1].address_components[0]?.long_name : 'Ukendt'} id='land'  placeholder='Land'/>
                <h2>Region</h2>
                <input type="text" value={address ? address.results[address.results.length - 2].address_components[0]?.long_name : 'Ukendt'} id='region' placeholder='Region'/>
                <h2>By</h2>
                <input type="text" value={address ? address.results[0].address_components[2]?.long_name : 'Ukendt'} id='city' placeholder='By'/>
                <div className='street'>
                    <div>
                                     <h2>Gade/vejnavn</h2>
                <input type="text" value={address ? address.results[address.results.length-address.results.length + 1].address_components[1]?.long_name : 'Ukendt'} id='street' placeholder='Gade/vejnavn'/>   
                    </div>

                <div>
                                  <h2>Nr.</h2>
                <input type="text" value={address ? address.results[0].address_components[0]?.long_name : 'Ukendt'} id='streetNum' placeholder='Nr.'/>  
                </div>

                </div>
                <div className='latLng'>
                    <div>
                  <h2>Lat</h2>
                <input type="number" id='inputLat'  placeholder='Lat' value={Lat} onChange={(e) => {setLat(e.target.value)}}/> 
                    </div>
                    <div>
                                       <h2>Long</h2>
                <input type="number" id='inputLong' placeholder='Long' value={Lng} onChange={(e) => {setLng(e.target.value)}}/> 
                    </div>

                </div>              
            </div> 

            <div className='generelt' style={generelt ? {display: 'block'} : {display: 'none'}}>
                <p>Om opgaven</p>
                
                <input type="text" id='alarm' placeholder='Første melding'/>
                <h2>Station</h2>
                <input type="text" id="station" list="stationsList" placeholder="Station" />

                  <datalist id="stationsList">
                  <option value="Albertslund"/>
                  <option value="Allerød"/>
                  <option value="Ballerup"/>
                  <option value="Beredskab & Sikkerhed"/>
                  <option value="Beredskab 4K"/>
                  <option value="Beredskab Fyn"/>
                  <option value="Beredskab Øst"/>
                  <option value="Bornholms Brandvæsen"/>
                  <option value="Brand & Redning MidtVest"/>
                  <option value="Brand & Redning Sønderjylland"/>
                  <option value="Brøndby"/>
                  <option value="Dragør"/>
                  <option value="Egedal"/>
                  <option value="Fredensborg"/>
                  <option value="Frederiksberg"/>
                  <option value="Frederiksborg Brand & Redning"/>
                  <option value="Frederikssund"/>
                  <option value="Furesø"/>
                  <option value="Gentofte"/>
                  <option value="Gladsaxe"/>
                  <option value="Glostrup"/>
                  <option value="Greve"/>
                  <option value="Gribskov Beredskab"/>
                  <option value="Halsnæs"/>
                  <option value="Helsingør Kommunes Beredskab"/>
                  <option value="Herlev"/>
                  <option value="Hillerød"/>
                  <option value="Hovedstadens Beredskab"/>
                  <option value="Hvidovre"/>
                  <option value="Høje-Taastrup"/>
                  <option value="Hørsholm"/>
                  <option value="Ishøj"/>
                  <option value="København"/>
                  <option value="Køge Brand & Redning"/>
                  <option value="Lejre Brandvæsen"/>
                  <option value="Lolland-Falster Brandvæsen"/>
                  <option value="Lyngby-Taarbæk"/>
                  <option value="Midt- og Sydsjællands Brand & Redning"/>
                  <option value="Midtjysk Brand & Redning"/>
                  <option value="Nordjyllands Beredskab"/>
                  <option value="Nordsjællands Brandvæsen"/>
                  <option value="Nordvestjyllands Brandvæsen"/>
                  <option value="Roskilde Brandvæsen"/>
                  <option value="Rudersdal"/>
                  <option value="Rødovre"/>
                  <option value="Slagelse Brand og Redning"/>
                  <option value="Solrød Brand & Redning"/>
                  <option value="Sorø Kommunes Beredskab"/>
                  <option value="Stevns Brand & Redning"/>
                  <option value="Sydvestjysk Brandvæsen"/>
                  <option value="Sydøstjyllands Brandvæsen"/>
                  <option value="Sønderborg Brand og Redning"/>
                  <option value="Trekantområdets Brandvæsen"/>
                  <option value="Tårnby"/>
                  <option value="Vallensbæk"/>
                  <option value="Vejle Brandvæsen"/>
                  <option value="Vestsjællands Brandvæsen"/>
                  <option value="Østjyllands Brandvæsen"/>
                  </datalist>
                <h2>Kilder</h2>
                <input type="text" id='notes' placeholder='Kilder'/>
                <h2>Bemærkninger fra operatør</h2>
                <input type="text"  placeholder='Bemærkninger fra operatør'/>
                <h2>Beskrivelse</h2>
                <textarea name="description" id="description" cols="60" rows="20" ></textarea>
            </div> 

            <div className='vejr' style={vejr ? {display: 'block'} : {display: 'none'}}>
                <p>Vejret på/omkring stedet</p>
                <div className='vind'>

                <h2>Vejrvarsler</h2>
                <input type="text" id='weatherWarning'  placeholder='Vejrvarsler'/>
                  </div>
            </div> 
                </form>

           </article>
           <article onClick={() => {
            setLat(parseFloat(document.getElementById('mapLat')?.innerText.replace(/,/g, '.')))
            setLng(parseFloat(document.getElementById('mapLong')?.innerText.replace(/,/g, '.')))
            setTimeout(() => {
                
                console.log('click');
              }, 1000);
              
            
           }}>


                <JustMap lat={Lat} lng={Lng}/>
                <button className='newAlarm' onClick={() => newRecord()}>Meld alarm</button>
           </article>
    </CreateSingleItemStyle>
  )
}

export default CreateSingleItem