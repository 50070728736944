import React from 'react'
import { HeliCardTwoStyle } from './HeliCardTwo.style'

function HeliCardTwo() {
  return (
    <HeliCardTwoStyle>
            <div className='reqNr'>
      <p className='title'>Reg Nr.</p>
      <p className='text'><strong>LA-ONZ</strong></p>
    </div>

    <div className='base'>
      <p className='title'>Base</p>
      <p className='text'>Ukendt</p>
    </div>

    <div className='sidsteFlyv'>
      <p className='title'>Lettet fra basen</p>
      <p className='text'>25-08-2023, 11:23</p>
    </div>

    <div className='ball'></div>
    </HeliCardTwoStyle>

  )
}

export default HeliCardTwo