import React from 'react'
import { SidebarStyle } from './Sidebar.style'
import plane from "../../Images/plane.svg"
import boat from "../../Images/boat.svg"
import logout from "../../Images/logout.svg"
import { NavLink } from 'react-router-dom'
import CheckOnline from '../CheckOnline/CheckOnline'

function logOut(params) {
  window.location.href = '/'
  sessionStorage.setItem('user', "")
  sessionStorage.setItem('userData', "")
}

function Sidebar() {
  return (
    <SidebarStyle>
           <NavLink to={"/flight"}><img src={plane} alt="plane" /></NavLink> 
          <NavLink><img src={boat} alt="boat" /></NavLink>          
          <CheckOnline />
          <div></div>



          <NavLink><img src={logout} alt="logout" onClick={() => logOut()} /></NavLink>

    </SidebarStyle>

  )
}

export default Sidebar