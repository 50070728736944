import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../Pages/Login/Login'
import Dashboard from '../Pages/Dashboard/Dashboard'
import TrafficMapTwo from '../Components/TrafficMap/TrafficMapTwo'
import Flightradar from '../Pages/Flightradar/Flightradar'
import SingleItem from '../Pages/SingleItem/SingleItem'
import CreateSingleItem from '../Pages/SingleItem/CreateSingleItem'
import UpdateSingleItem from '../Pages/SingleItem/UpdateSingleItem'
import LoadingScreen from '../Pages/LoadingScreen/LoadingScreen'

function AppRouter() {
  return (
    <Routes>
        <Route index element={<Login />}></Route>
        <Route path='/loading' element={<LoadingScreen />}></Route>
        <Route path='/dashboard' element={<Dashboard />}></Route>
        <Route path='/map/:id' element={<TrafficMapTwo />}></Route>
        <Route path='/flight' element={<Flightradar />}></Route>
        <Route path='/alarm/:id' element={<SingleItem />}></Route>
        <Route path='/updatealarm/:id' element={<UpdateSingleItem />}></Route>
        <Route path='/newalarm' element={<CreateSingleItem />}></Route>
    </Routes>
  )
}

export default AppRouter