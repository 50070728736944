import React from 'react'
import { FlightradarStyle } from './Flightradar.style'

import { NavLink } from 'react-router-dom'
import HeliCard from './HeliCard'
import HeliCardTwo from './HeliCardTwo'

function Flightradar() {
  return (
    <FlightradarStyle>
            <article>
              <NavLink to={"/dashboard"}>{"<--"} Alarmer</NavLink>
              <div>
                <input type="text" placeholder='Søg efer reg nr' />
                <div>
                  <button>Søg</button>
                </div>
              </div>
                <HeliCard />
                <hr />
                <h2>Helikoptere i luften</h2>
<HeliCardTwo />
            </article>
            <iframe 
            loading="lazy" 
            src="https://www.flightradar24.com/simple?lat=56.13168177139217&lon=8.896922416881525&z=14" 
            name="flightradar" 
            
            scrolling="No"></iframe>

    </FlightradarStyle>
  )
}

export default Flightradar