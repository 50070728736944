import React from 'react'
import { HeaderStyle } from './Header.style'
import Notify from '../Notify/Notify'
import CheckFetch from '../RssToJson/CheckFetch'
import CheckTraffic from '../RssToJson/CheckTraffic'

function Header() {
  return (
    <HeaderStyle>
        <img src="" alt="avatar" />
        <Notify />
        <CheckFetch />
        <CheckTraffic />
    </HeaderStyle>
  )
}

export default Header