import styled from "styled-components";

export const NotifyStyleStyle = styled.div`
ul{
    position: absolute;
    width: 15vw;
    border: 1px solid black;
    border-radius: 20px;
    padding: 1vh 1vw;
    list-style: none;
    display: grid;
    grid-template-columns: 50% 50%;
    background-color: white;
    li{
    width: 100%;
    border-bottom: 1px solid black;
    }
}
`