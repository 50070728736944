import styled from "styled-components";

export const HeaderStyle = styled.header`
height: 5vh;
background-color: white;
display: grid;
grid-template-columns: repeat(4, 1fr);
justify-items: center;
border-bottom: 1px solid black;
ul{
    z-index: 2;
}
`