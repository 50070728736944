import React, { useEffect, useState } from 'react'
import { NotifyStyleStyle } from './Notify.style'
import supabase from "../../supabase.js"

Notification.requestPermission().then((result) => {
    console.log(result);
  });

function formatDate(timestamp) {
    let formattedDate = ``;
    const dateObj = new Date(timestamp);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear().toString();
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  if (new Date(timestamp).getDate() === new Date().getDate()) {
    formattedDate = `Idag kl. ${hours}.${minutes}`;
  }

  if (new Date(timestamp).getDate() - 1 === new Date().getDate()) {
    formattedDate = `Igår ${hours}.${minutes}`;
  }

    return formattedDate;
  }
  function compareNumbers(a, b) {
    return b.id - a.id;
  }
function Notify() {
const [showList, setShowList] = useState(false)
const [data, setData] = useState([])
const [newData, setNewData] = useState([])
const [isNew, setIsNew] = useState(false)

useEffect(() => {
    async function getNotify() {
      let { data: echoNotify1, error } = await supabase
        .from('echoNotify')
        .select('*')
        .range(0, 4)
        .order('id', { ascending: false })
      setData(echoNotify1);
      console.log(echoNotify1);
  
      const echoNotify2 = supabase.channel('custom-insert-channel').on(
        'postgres_changes',
        { event: 'INSERT', schema: 'public', table: 'echoNotify' },
        (payload) => {
          console.log('Change received!', payload.new);
            setIsNew(true)
          setData((prevData) => [...prevData, payload.new]); // Append payload to newData
          const img = "https://cdn.icon-icons.com/icons2/2620/PNG/512/among_us_player_red_icon_156942.png";
const text = `Ny notifikation: ${payload.new.notification}`;
const notification = new Notification("To do list", { body: text, icon: img });
        }
      );
  
      echoNotify2.subscribe();
    }
  
    getNotify();
  }, []);
  


  return (
    <NotifyStyleStyle>
            <div style={{color: isNew ? 'red' : 'black'}} onClick={() => {
                if (showList) {
                    setShowList(false)
                }else{
                    setShowList(true)
                }
                setTimeout(() => {
                setIsNew(false)
                }, 2000);
            }}>Notify</div>
          {showList ? <ul>
            {/* {newData.map((item, index) => (
                <>
                <li style={{color: isNew ? 'red' : 'black'}}>{item.notification}</li>
<li style={{color: isNew ? 'red' : 'black'}}>{formatDate(item.created_at)}</li>
</>

                  ))} */}
                {data.sort(compareNumbers).map((item, index) => (
                <>
                <li>{item.notification}</li>
<li>{formatDate(item.created_at)}</li>
</>
                  ))}
            </ul> : null}
    </NotifyStyleStyle>
  )
}

export default Notify