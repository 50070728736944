import styled from "styled-components";
import plane from "../../Images/plane.svg"
import boat from "../../Images/boat.svg"

export const SidebarStyle = styled.aside`
background-color: white;
border-right: solid 1px black;
margin-right: 1vw;
padding-top: 1vh;
display: grid;
grid-template-rows: 5% 5% 85%;
  justify-content: center;
  height: 100%;

img{
    height: 4vh;

}
 


`