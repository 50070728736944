
import React, { useEffect, useRef, useState } from "react";
import { render } from "react-dom";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L, { MarkerCluster } from 'leaflet'; // Import the Leaflet library for creating custom icons
import geojsonBbox from "geojson-bbox";
import axios from "axios";
import supabase from "../../supabase.js"
import { NavLink } from "react-router-dom";
import proj4 from "proj4";
import restepladsA from '../../restepladsA.json'
import restepladsB from '../../restepladsB.json'
import trafficcam from '../../webcamera.json'
import vejarbejde from '../../vejarbejde.json'
import MarkerClusterGroup from "react-leaflet-cluster";
import 'leaflet/dist/leaflet.css'
import { TrafficMapStyle } from "./TrafficMap.style.js";
const vejarbejdeIcon = function (cluster: MarkerCluster) {
  return L.divIcon({
    html: ` <img style="height: 5vh;" src="https://trafikkort.vejdirektoratet.dk/assets/roadwork/layer-switcher-icon.png"><span>${cluster.getChildCount()}</span></img>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(33, 33, true),
  })
}
function removeSubstring(inputString) {
  const substringToRemove = "https:\/\/trafikkort-prod.appspot.com\/rest\/webcam\/getimage\/http%3A%2F%2Fwebcam.trafikken.dk%2Fwebcam%2F";
  return inputString.replace(substringToRemove, "");
}
const camIcon = function (cluster: MarkerCluster) {
  return L.divIcon({
    html: ` <img style="height: 5vh;" src="https://trafikkort.vejdirektoratet.dk/assets/webcam/layer-switcher-icon.png"><span>${cluster.getChildCount()}</span></img>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(33, 33, true),
  })
}

const restIcon = function (cluster: MarkerCluster) {
  return L.divIcon({
    html: ` <img style="height: 5vh;" src="https://trafikkort.vejdirektoratet.dk/assets/rest-area/layer-switcher-icon.png"><span>${cluster.getChildCount()}</span></img>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(33, 33, true),
  })
}
const DaIcon = new L.Icon({
  iconUrl: 'https://cdn.icon-icons.com/icons2/2620/PNG/512/among_us_player_red_icon_156942.png', // Replace this with the URL of your custom icon image
  iconSize: [32, 32], // Adjust the icon size as needed
  iconAnchor: [16, 32], // Adjust the icon anchor to position the icon correctly on the marker's position
});

function TrafficMap(props) {
  const [trafficDataList, setTrafficDataList] = useState([]);
  const [trafficList, setTrafficList] = useState([]);
  const [alarms, setAlarms] = useState([]);
  const [roadblocks, setRoadblocks] = useState();
  const [data, setData] = useState([]);
  const [showCam, setShowCam] = useState(false);
  const [showRoadwork, setRoadwork] = useState(false);
  const [showRest, setRest] = useState(false);

const convertCoordinates = (utmX,utmY) => {
    const parsedUtmX = parseFloat(utmX);
    const parsedUtmY = parseFloat(utmY);
    const parsedUtmZone = parseInt(32);
    const utmProjection = `+proj=utm +zone=${parsedUtmZone} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`;
    const wgs84Projection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    const utmCoordinates = [parsedUtmX, parsedUtmY];
    const latLngCoordinates = proj4(utmProjection, wgs84Projection, utmCoordinates);

return [latLngCoordinates[1], latLngCoordinates[0]]
};

function findIndexByPropertyValue(array, propertyName, targetValue) {
  for (let i = 0; i < array.length; i++) {
      if (array[i][propertyName] === targetValue) {
          return i; // Return the index when the property value matches
      }
  }
  return -1; // Return -1 if the object with the desired property value is not found
}


    const boundingBoxList = [
      { north: 56.4502, south: 55.872, east: 9.8812, west: 8.8979 },
      { north: 56.57, south: 55.99, east: 11.05, west: 10.06 },
      { north: 56.6, south: 56.03, east: 10.19, west: 9.21 },
      { north: 56.65, south: 56.08, east: 9.14, west: 8.15 },
      { north: 56.04, south: 55.46, east: 9.07, west: 8.09 },
      { north: 56.099, south: 55.51, east: 9.99, west: 9.01 },
      { north: 55.4, south: 54.81, east: 10.15, west: 9.16 },
      { north: 55.61, south: 55.02, east: 10.87, west: 9.89 },
      { north: 56.14, south: 55.56, east: 12.67, west: 11.69 },
      { north: 55.46, south: 54.87, east: 15.38, west: 14.39 },
      { north: 55.44, south: 54.84, east: 9.25, west: 8.26 },
      { north: 55.21, south: 54.61, east: 11.03, west: 10.04 },
      { north: 55.86, south: 55.27, east: 11.82, west: 10.83 },
      { north: 55.86, south: 55.27, east: 12.8, west: 11.82 },
      { north: 55.22, south: 54.63, east: 12.63, west: 11.65 },
      { north: 55.21, south: 54.61, east: 11.84, west: 10.85 },
    ];
    function isValueInObject(obj, value) {
      // Loop through the object properties
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          // Check if the property value matches the target value
          if (obj[key] === value) {
            return true;
          }
        }
      }
      // Value not found in the object
      return false;
    }
    
    const removeDuplicatesByProperty = (arr, propertyName) => {
      return arr.filter((obj, index, self) =>
        index === self.findIndex((o) => o[propertyName] === obj[propertyName])
      );
    };
    function convertToReadableDate(dateString) {
      const date = new Date(dateString);
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
      return date.toLocaleString(undefined, options);
    }

    function isDateInPast(inputDate) {
      if (!inputDate) {
          throw new Error('Input date is undefined or null.');
      }
      
      const parts = !inputDate ? "03-07-2023 kl. 05:00".split(' kl. ') : inputDate.split(' kl. ')
      
      if (parts.length !== 2) {
          throw new Error('Input date is not in the expected format.');
      }
  
      const datePart = parts[0];
      const timePart = parts[1];
  
      const [day, month, year] = datePart.split('-').map(Number);
      const [hours, minutes] = timePart.split(':').map(Number);
  
      const inputDateObject = new Date(year, month - 1, day, hours, minutes);
      const currentDate = new Date();
  
      return inputDateObject < currentDate;
  }
  

  
  let sec = new Date().getSeconds()  

  let count = 0

  useEffect(() => {
    async function getAllServices() {
      
let { data: echoFetch, error } = await supabase
.from('echoFetch')
.select('serviceData,layerId')

setData(echoFetch)
    }

    getAllServices()
  }, [])

useEffect(() => {


      // Function to fetch traffic data for each bounding box
      const fetchTrafficData = async (boundingBox) => {
        const apiKey = 'dGzjuBv5SSfNv97ur4rQqmyjnObGOwa2';
        const filters = 'incidents,congestion,events';
  
        try {
          const response = await axios.get(
            `https://www.mapquestapi.com/traffic/v2/incidents?key=${apiKey}&boundingBox=${boundingBox.south},${boundingBox.west},${boundingBox.north},${boundingBox.east}&filters=${filters}`
          );
          return response.data.incidents;
        } catch (error) {
          console.error('Error fetching traffic data:', error);
          return [];
        }
      };
      
      // Fetch traffic data for each bounding box in the list
const fetchTrafficDataForAllBoundingBoxes = async () => {
  const promises = boundingBoxList.map(fetchTrafficData);
  try {
    const trafficDataLists = await Promise.all(promises);
    const allTrafficData = trafficDataLists.flat();
    const uniqueTrafficData = removeDuplicatesByProperty(allTrafficData, 'id');
    setTrafficDataList(uniqueTrafficData);
  } catch (error) {
    console.error('Error fetching traffic data:', error);
  }
  
}
if (count === 0) {
//fetchTrafficDataForAllBoundingBoxes();
count++
console.log('*i only fire once');
}
  

}, [])


    useEffect(() => {
  const fetchAlarms = async () => {
    let { data: echoAlarmer, error } = await supabase
.from('echoAlarmer')
.select('*')
.eq('dato', props.date)

setAlarms(echoAlarmer)
  }      

fetchAlarms()
    }, [props.date]);
    let markers = [
        { id: 1, position: [56.1331484, 8.8976704], title: 'Marker 1', content: 'This is marker 1.' },
        { id: 2, position: [56.1234567, 8.9876543], title: 'Marker 2', content: 'This is marker 2.' },
        
      ];
    
      // Create a custom marker icon

       function icon(eventcode) {
        let iconToUse;
         switch (eventcode) {
           case "layer_id_19C1":
             iconToUse = 'https://trafikkort.vejdirektoratet.dk/assets/roadwork/layer-switcher-icon.png'
             break;
             case "Planlagte vejarbejder":
               iconToUse = 'http://content.mqcdn.com/mqtraffic/const_sev.png'
               break;
               case 'layer_id_10A':
                 iconToUse = 'https://trafikkort.vejdirektoratet.dk/assets/rest-area/layer-switcher-icon.png'
                 break;
                 case "layer_id_19H1":
                   iconToUse = 'http://content.mqcdn.com/mqtraffic/incid_sev.png'
                   break;
                   case 'layer_id_13':
                     iconToUse = 'https://trafikkort.vejdirektoratet.dk/assets/webcam/layer-switcher-icon.png'
                     break;
                     case 'layer_id_22':
                       iconToUse = 'http://content.mqcdn.com/mqtraffic/incid_sev.png'
                       break;
                       case 'supabase':
                        iconToUse = 'http://content.mqcdn.com/mqtraffic/incid_sev.png'
                        break;
                       case 803:
                         iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                         break;
                         case 802:
                           iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                           break;
                           case 518:
                             iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                             break;
                             case 708:
                               iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                               break;
                               case 500:
                                 iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                                 break;
                                 case 746:
                                   iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                                   break;
                                   case 742:
                                     iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                                     break;
                                     case 'layer_id_19F1':
                                       iconToUse = 'http://content.mqcdn.com/mqtraffic/event_min.png'
                                       break;
                                       case 'layer_id_19G1':
                                         iconToUse = 'http://content.mqcdn.com/mqtraffic/incid_min.png'
                                         break;
                                         case 'layer_id_19I1':
                                         iconToUse = 'http://content.mqcdn.com/mqtraffic/congestion_min.png'
                                         break;
                                         case 'layer_id_19A1':
                                           iconToUse = 'http://content.mqcdn.com/mqtraffic/incid_min.png'
                                           break;
                                           case 210:
                                             iconToUse = 'http://content.mqcdn.com/mqtraffic/incid_min.png'
                                             break;
           default:
             iconToUse = 'https://cdn.icon-icons.com/icons2/2620/PNG/512/among_us_player_red_icon_156942.png'
             break;
         }
        

        
         return new L.Icon({
           iconUrl: iconToUse, // Replace this with the URL of your custom icon image
           iconSize: [32, 32], // Adjust the icon size as needed
           iconAnchor: [16, 32], // Adjust the icon anchor to position the icon correctly on the marker's position
         });
       }
      // console.log(alarms);
      // console.log(trafficDataList);
      console.log(restepladsA.layerId);

      function getHeading(val) {
        let deg;
        if (val < 39 && val > 0) {
            deg = "Nord ⬆️"; 
        } else if (val < 89 && val > 44) {
            deg = "Nordøst ↗️";
        } else if (val < 134 && val > 90) {
            deg = "Øst ➡️";
        } else if (val < 179 && val > 135) {
            deg = "Sydøst ↘️";
        } else if (val < 224 && val > 180) {
            deg = "Syd ⬇️";
        } else if (val < 269 && val > 225) {
            deg = "Sydvest ↙️";
        } else if (val < 314 && val > 270) {
            deg = "Vest ⬅️";
        } else if (val < 360 && val > 315) {
            deg = "Nordvest ↖️";
        }
        return deg;
    }
    console.log(data);
  return (
    <TrafficMapStyle>
<div className="filterDiv">
<label htmlFor="cam">Trafik kamera</label>
  <input type="checkbox" name="cam" id="" onChange={() => {
    if (showCam) {
      setShowCam(false)
    }else{
      setShowCam(true)
    }
  }}/>

<label  htmlFor="roadwork">Vejarbejde</label>
  <input type="checkbox" name="roadwork" id="" onChange={() => {
    if (showRoadwork) {
      setRoadwork(false)
    }else{
      setRoadwork(true)
    }
  }}/>

<label htmlFor="rest">Restepladser</label>
  <input type="checkbox" name="rest" id="" onChange={() => {
    if (showRest) {
      setRest(false)
    }else{
      setRest(true)
    }
  }}/>
</div>

      <MapContainer
        style={{ height: "95%", width: "100%" }}
        zoom={15}
        center={[56.1331484, 8.8976704]}
        onClick={() => console.log("Click")}
        iconUrl={"https://cdn.icon-icons.com/icons2/2620/PNG/512/among_us_player_red_icon_156942.png"}

      >
{/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
        <ReactLeafletGoogleLayer
        apiKey='AIzaSyAfPOw-RMPwGU-Gt96Ur0OmVwxgNBbyC5Q'
          type="roadmap"
          googleMapsAddLayers={[
            { name: "TransitLayer" },
            { name: "TrafficLayer" }
          ]}
          
        >
          
          </ReactLeafletGoogleLayer>   


{/* Begivenheder */}
{data[findIndexByPropertyValue(data, 'layerId', 'layer_id_19F1')]?.serviceData?.features?.map((marker, index) => (
            
           !isDateInPast(marker?.properties?.endPeriod) ? <Marker
               key={marker.id}
               position={convertCoordinates(
                 marker?.geometry?.coordinates[0] || 692700.73 * index,
                 marker?.geometry?.coordinates[1] || 0
               )}
               icon={icon(data[findIndexByPropertyValue(data, 'layerId', 'layer_id_19F1')].serviceData.layerId)}
             >
               <Popup>
                 <h3>{marker?.properties?.title}</h3>
                 <div className="restDiv" dangerouslySetInnerHTML={{ __html: marker?.properties?.description }} />
               </Popup>
             </Marker> : null
           ))}  

           {/* Spærret vej */}
{data[findIndexByPropertyValue(data, 'layerId', 'layer_id_19A1')]?.serviceData?.features?.map((marker, index) => (
            
            !isDateInPast(marker?.properties?.endPeriod || 0) ? <Marker
               key={marker.id}
               position={convertCoordinates(
                 marker?.geometry?.coordinates[0] || 692700.73 * index,
                 marker?.geometry?.coordinates[1] || 0
               )}
               icon={icon(data[findIndexByPropertyValue(data, 'layerId', 'layer_id_19A1')].serviceData.layerId)}
             >
               <Popup>
                 <h3>{marker?.properties?.title}</h3>
                 <div className="restDiv" dangerouslySetInnerHTML={{ __html: marker?.properties?.description }} />
               </Popup>
             </Marker> : null
           ))} 

{/* Andre meldinger */}
{data[findIndexByPropertyValue(data, 'layerId', 'layer_id_19H1')]?.serviceData?.features?.map((marker, index) => (
            
            <Marker
               key={marker.id}
               position={convertCoordinates(
                 marker?.geometry?.coordinates[0] || 692700.73 * index,
                 marker?.geometry?.coordinates[1] || 0
               )}
               icon={icon(data[findIndexByPropertyValue(data, 'layerId', 'layer_id_19H1')].serviceData.layerId)}
             >
               <Popup>
                 <h3>{marker?.properties?.title}</h3>
                 <div className="restDiv" dangerouslySetInnerHTML={{ __html: marker?.properties?.description }} />
               </Popup>
             </Marker>
           ))}   
     
     {/* Kø dannelse */}
     {data[findIndexByPropertyValue(data, 'layerId', 'layer_id_19I1')]?.serviceData?.features?.map((marker, index) => (
            
   <Marker
               key={marker.id}
               position={convertCoordinates(
                 marker?.geometry?.coordinates[0] || 692700.73 * index,
                 marker?.geometry?.coordinates[1] || 0
               )}
               icon={icon(data[findIndexByPropertyValue(data, 'layerId', 'layer_id_19I1')].serviceData.layerId)}
             >
               <Popup>
                 <h3>{marker?.properties?.title}</h3>
                 <div className="restDiv" dangerouslySetInnerHTML={{ __html: marker?.properties?.description }} />
               </Popup>
             </Marker>
           ))}   
     

      <MarkerClusterGroup chunkedLoading iconCreateFunction={vejarbejdeIcon}>
{showRoadwork ? <>
  {data[findIndexByPropertyValue(data, 'layerId', 'layer_id_19C1')]?.serviceData?.features?.map((marker, index) => (
            
            
          marker?.properties?.suspended || marker?.properties?.future ? <Marker
               key={marker.id}
               position={convertCoordinates(
                 marker?.geometry?.coordinates[0] || 692700.73 * index,
                 marker?.geometry?.coordinates[1] || 0
               )}
               icon={icon(data[findIndexByPropertyValue(data, 'layerId', 'layer_id_19C1')].serviceData.layerId)}
             >
               <Popup>
                 <h3>{marker?.properties?.title}</h3>
                 <div className="restDiv" dangerouslySetInnerHTML={{ __html: marker?.properties?.description }} />
               </Popup>
             </Marker> : null
           ))}   
</> : null}

      </MarkerClusterGroup>


      <MarkerClusterGroup chunkedLoading iconCreateFunction={camIcon}>
{showCam ? <>
{trafficcam.features?.map((marker,index) => (
 <Marker
    key={marker.id}
    position={convertCoordinates(marker.geometry.coordinates[0], marker.geometry.coordinates[1]  )}
    icon={icon(trafficcam.layerId)}
  >
    <Popup>
      
      <p>Mod {getHeading(marker.properties?.bearing)}</p>
      <a href={'http://webcam.trafikken.dk/webcam/' + removeSubstring(marker.properties?.src)} target="_blank" rel="noopener noreferrer">
      <img src={'http://webcam.trafikken.dk/webcam/' + removeSubstring(marker.properties?.src)} alt="Traffic cam" />
        </a>
    </Popup>
  </Marker> 
))}
</> : null}
          </MarkerClusterGroup>
          <MarkerClusterGroup chunkedLoading iconCreateFunction={restIcon}> 
 {showRest ? <>
{restepladsA.features?.map((marker,index) => (
  <Marker
    key={marker.id}
    position={convertCoordinates(marker.geometry.coordinates[0], marker.geometry.coordinates[1]  )}
    icon={icon(restepladsA.layerId)}
  >
    <Popup>
      <h3>{marker.properties?.title}</h3>

      <div className="restDiv" dangerouslySetInnerHTML={{ __html: marker.properties?.description }} />
      
      
    </Popup>
  </Marker>
))}

{restepladsB.features?.map((marker,index) => (
  <Marker
    key={marker.id}
    position={convertCoordinates(marker.geometry.coordinates[0], marker.geometry.coordinates[1]  )}
    icon={icon(restepladsA.layerId)}
  >
    <Popup>
      <h3>{marker.properties?.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: marker.properties?.description }} />
      
    </Popup>
  </Marker>
))}
</> : null}
</MarkerClusterGroup>
          {alarms?.map((marker, index) => (
            <Marker key={marker.id} position={[(marker.lat + 2) * index, (marker.long)]} icon={icon('supabase')}>
              <Popup>
                <h3>{marker.melding}</h3>
                <p>{marker.beskrivelse}</p>
                <p> første melding: {marker.tid} {marker.dato}</p>
                <NavLink to={`/alarm/${marker.id}`}>Se mere</NavLink>
              </Popup>
            </Marker>
          ))}
              
      </MapContainer>
    </TrafficMapStyle>
  );
}

const rootElement = document.getElementById("root");
render(<TrafficMap />, rootElement);

export default TrafficMap
