import React, { useState } from 'react'
import { LoginStyle } from './Login.style'
import { NavLink } from 'react-router-dom'
import supabase from "../../supabase.js"

function Login() {
    const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  if (sessionStorage.getItem('user')) {
    if (window.location.pathname !== '/dashboard') {
      window.location.href = '/dashboard';
    }
  } else {
    if (window.location.pathname !== '/') {
      window.location.href = '/';
    }
  }
  
  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email.trim() || !password.trim()) {
        console.error('Please enter a valid email and password');
        return;
      }

    try {
      const { user, error } = await supabase.auth.signInWithPassword({
        email,
        password}
      );

      if (error) {
        throw error;
      }
      
let { data: users, error3 } = await supabase
.from('echoUsers')
.select('id')
.eq('email', email)


const { data, error2 } = await supabase
  .from('echoUsers')
  .update({ isonline: true })
  .eq('email', email)
  .select()

      console.log('User:', email);
      sessionStorage.setItem('user', email)
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Login error:', error.message);
    }
  };
  return (
      <LoginStyle>
        <article className="login-container">
  <h1 className="title">Echo</h1>
  <form onSubmit={handleLogin}>
  <input type="email" 
  className="input-field" 
  value={email}
  onChange={(e) => setEmail(e.target.value)}
  
  />
  <input type="password" 
  className="input-field" 
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  />
  <a href="" className="forgot-password">Glemt dit kodeord?</a>
  <button type='submit' className="login-button" oncl>Login</button>
  </form>
  <NavLink to={"/loading"}>Login</NavLink>
</article>
<article className='login-map-container'>
<img src="https://wallpapercave.com/wp/wp3302694.jpg" alt="" />
</article>
      </LoginStyle>
  )
}

export default Login