import { createGlobalStyle } from 'styled-components';
 
export const GlobalStyle = createGlobalStyle`

.main-container{
    display: grid;
    grid-template-columns: 5% 95%;
    overflow: hidden;                                               
}


body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  width: 100vw;
  height: 99vh;
}
`;