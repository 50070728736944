import styled from "styled-components";

export const LoginStyle = styled.section`  
display: grid;
  grid-template-columns: 60% 40%;

.login-map-container{
  width: 100%;
  background-color: gray;
}

.login-container {
  width: 100%;
  max-width: 400px; /* Limit the width of the container */
  margin: 0 auto; /* Center the container horizontally */
  text-align: center; /* Center the content inside the container */
}

.title {
  font-size: 95px;
  margin-bottom: 20px;
  font-family: 'Audiowide', cursive;
  margin-top: 30vh;
}

img{
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.input-field {
  display: block;
  width: 50%; /* Take 50% of the width of the article */
  margin: 10px auto; /* Center the input fields horizontally */
  padding: 10px;
  border-radius: 10px;
}

.forgot-password {
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  color: #333;
}

.login-button {
  display: block;
  width: 50%; /* Take 50% of the width of the article */
  margin: 20px auto; /* Center the button horizontally */
  padding: 10px;
  background-color: blue; /* Set the button background color to blue */
  color: #fff; /* Set the text color to white */
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

/* Remove default button styling */
button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
}

/* Optional: add hover effect to the button */
.login-button:hover {
  background-color: darkblue;
}
`