
import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { MapContainer as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L from 'leaflet'; // Import the Leaflet library for creating custom icons
import geojsonBbox from "geojson-bbox";
import axios from "axios";
import { useParams } from "react-router";

function TrafficMapTwo() {
  const [trafficDataList, setTrafficDataList] = useState([]);
  const { id } = useParams()
const coordinates = id ? id : "0,0";
  const [latitude, longitude] = coordinates.split(",").map(parseFloat);
  const [lat,setLat] = useState(latitude)
  const [lng,setLng] = useState(longitude                                 )
  

    const boundingBoxList = [
      { north: 56.4502, south: 55.872, east: 9.8812, west: 8.8979 },
      { north: 56.57, south: 55.99, east: 11.05, west: 10.06 },
      { north: 56.6, south: 56.03, east: 10.19, west: 9.21 },
      { north: 56.65, south: 56.08, east: 9.14, west: 8.15 },
      { north: 56.04, south: 55.46, east: 9.07, west: 8.09 },
      { north: 56.099, south: 55.51, east: 9.99, west: 9.01 },
      { north: 55.4, south: 54.81, east: 10.15, west: 9.16 },
      { north: 55.61, south: 55.02, east: 10.87, west: 9.89 },
      { north: 56.14, south: 55.56, east: 12.67, west: 11.69 },
      { north: 55.46, south: 54.87, east: 15.38, west: 14.39 },
      { north: 55.44, south: 54.84, east: 9.25, west: 8.26 },
      { north: 55.21, south: 54.61, east: 11.03, west: 10.04 },
      { north: 55.86, south: 55.27, east: 11.82, west: 10.83 },
      { north: 55.86, south: 55.27, east: 12.8, west: 11.82 },
      { north: 55.22, south: 54.63, east: 12.63, west: 11.65 },
      { north: 55.21, south: 54.61, east: 11.84, west: 10.85 },
    ];
    const removeDuplicatesByProperty = (arr, propertyName) => {
      return arr.filter((obj, index, self) =>
        index === self.findIndex((o) => o[propertyName] === obj[propertyName])
      );
    };
    function convertToReadableDate(dateString) {
      const date = new Date(dateString);
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
      return date.toLocaleString(undefined, options);
    }
    
    useEffect(() => {
      // Function to fetch traffic data for each bounding box
      const fetchTrafficData = async (boundingBox) => {
        const apiKey = 'nhYpt5s819DEup0ZEkcytjlnnh4aTaDz';
        const filters = 'incidents,construction,event,congestion';
  
        try {
          const response = await axios.get(
            `https://www.mapquestapi.com/traffic/v2/incidents?key=${apiKey}&boundingBox=${boundingBox.south},${boundingBox.west},${boundingBox.north},${boundingBox.east}&filters=${filters}`
          );
          return response.data.incidents;
        } catch (error) {
          console.error('Error fetching traffic data:', error);
          return [];
        }
      };
  
      // Fetch traffic data for each bounding box in the list
      const fetchTrafficDataForAllBoundingBoxes = async () => {
        const promises = boundingBoxList.map(fetchTrafficData);
        try {
          const trafficDataLists = await Promise.all(promises);
          const allTrafficData = trafficDataLists.flat();
          const uniqueTrafficData = removeDuplicatesByProperty(allTrafficData, 'id');
          setTrafficDataList(uniqueTrafficData);
        } catch (error) {
          console.error('Error fetching traffic data:', error);
        }
      };
  
     // fetchTrafficDataForAllBoundingBoxes();
    }, []);
    let markers = [
        { id: 1, position: [56.1331484, 8.8976704], title: 'Marker 1', content: 'This is marker 1.' },
        { id: 2, position: [56.1234567, 8.9876543], title: 'Marker 2', content: 'This is marker 2.' },
        
      ];
    
      // Create a custom marker icon

      function icon(eventcode) {
       let iconToUse;
        switch (eventcode) {
          case 701:
            iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
            break;
            case 735:
              iconToUse = 'http://content.mqcdn.com/mqtraffic/const_sev.png'
              break;
              case 401:
                iconToUse = 'http://content.mqcdn.com/mqtraffic/incid_sev.png'
                break;
                case 1472:
                  iconToUse = 'http://content.mqcdn.com/mqtraffic/incid_min.png'
                  break;
                  case 1474:
                    iconToUse = 'http://content.mqcdn.com/mqtraffic/incid_min.png'
                    break;
                    case 1209:
                      iconToUse = 'http://content.mqcdn.com/mqtraffic/incid_min.png'
                      break;
                      case 803:
                        iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                        break;
                        case 802:
                          iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                          break;
                          case 518:
                            iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                            break;
                            case 708:
                              iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                              break;
                              case 500:
                                iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                                break;
                                case 746:
                                  iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                                  break;
                                  case 742:
                                    iconToUse = 'http://content.mqcdn.com/mqtraffic/const_min.png'
                                    break;
                                    case 1527:
                                      iconToUse = 'http://content.mqcdn.com/mqtraffic/event_sev.png'
                                      break;
                                      case 922:
                                        iconToUse = 'http://content.mqcdn.com/mqtraffic/incid_min.png'
                                        break;
          default:
            iconToUse = 'https://cdn.icon-icons.com/icons2/2620/PNG/512/among_us_player_red_icon_156942.png'
            break;
        }

        return new L.Icon({
          iconUrl: iconToUse, // Replace this with the URL of your custom icon image
          iconSize: [32, 32], // Adjust the icon size as needed
          iconAnchor: [16, 32], // Adjust the icon anchor to position the icon correctly on the marker's position
        });
      }
      
      console.log(trafficDataList);
  return (
    <div style={{zIndex: -1}}>
      {id ? 
      <LeafletMap
        style={{ height: "100vh", width: "100vw" }}
        zoom={15}
        center={[lat, lng]}
        onClick={() => console.log("Click")}

      >

        <ReactLeafletGoogleLayer
        apiKey='AIzaSyAfPOw-RMPwGU-Gt96Ur0OmVwxgNBbyC5Q'
          type="roadmap"
          googleMapsAddLayers={[
            { name: "TransitLayer" },
            { name: "TrafficLayer" }
          ]}
          
        />      


{trafficDataList.map((marker) => (
            <Marker key={marker.id} position={[marker.lat, marker.lng]} icon={icon(marker.eventCode)}>
              <Popup>
                <h3>{marker.shortDesc}</h3>
                <p>{marker.fullDesc}</p>
                {/* <h2>{marker.eventCode}</h2>
                <h2>{marker.iconURL}</h2> */}
                <p>From {convertToReadableDate(marker.startTime)} to {convertToReadableDate(marker.endTime)}</p>
                
              </Popup>
            </Marker>
          ))}

      </LeafletMap> : null}
    </div>
  );
}

const rootElement = document.getElementById("root");
render(<TrafficMapTwo />, rootElement);

export default TrafficMapTwo