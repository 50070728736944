import React, { useEffect, useState } from 'react'
import axios from "axios";
import supabase from "../../supabase.js"

const ids = [
        '',
        'Begivenheder',
        'Kø dannelse',
        'Specielle meldinger',
        'Andre meldinger',
        'Spærret vej',
        'Vejarbejde',
        'Stærke vindstød'
    ]
function CheckFetch() {
    const [progress, setProgress] = useState('')
    const [supaData, setSupaData] = useState([])
    const [urls, setUrls] = useState([])
    const [isReady, setIsReady] = useState(false)
    const [fetchedData, setFetchedData] = useState([])
    let indexx = 0
    useEffect(() => {
        setProgress('Henter supabase data...')
        async function getSupaData() {          
let { data: echoFetch, error } = await supabase
.from('echoFetch')
.select('*')

setSupaData(echoFetch)
setProgress('Henter supabase data hentet!')
        }
getSupaData()
    }, [])

    useEffect(() => {
        setProgress('Tjekker om data i databasen er up to date...')
        for (let index = 0; index < supaData.length; index++) {
            const element = supaData[index];
            console.log(element.interval_in_sec);
            if (index === supaData.length - 1) {
                setIsReady(true)
            }
            if (element.interval_in_sec < parseInt((new Date().getTime() - new Date(element.last_fetch).getTime()) / 1000)) {
                console.log('Need new fetch');
                setProgress('Dataen i databasen er ikke up to date.')
                setUrls((prevData) => [...prevData, element.link]); // Append payload to newData
            }else{
                console.log('No need to fetch'); 
                setProgress('Færdig med at indhente data!')
            }
        }

    }, [supaData])

    useEffect(() => {
        setProgress('Opdatere data i databasen...')
        const fetchData = async () => {
            const proxyUrl = 'https://cors-anywhere.herokuapp.com/'; // CORS proxy URL
            if (urls.length !== 0) {
                try {
      
                    const requests = urls.map((url) => axios.get(proxyUrl + url));
                    const responses = await Promise.all(requests);
            
                    const responseData = responses.map((response) => response.data);
                    console.log(responseData);
                    setFetchedData(responseData)
                    setProgress('Data er nu opdateret')
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }
            }else{
                console.log('Nothing to fetch');
            }
          };
      
          fetchData();
    }, [isReady])

    useEffect(() => {
       async function updateDatabase(newData,layerId) {
            
const { data, error } = await supabase
.from('echoFetch')
.update({ serviceData: newData })
.eq('layerId', layerId)
.select()
 if (error) {
    console.log('Insert error:',error);
 }
        }

        for (let index = 0; index < fetchedData.length; index++) {
            const element = fetchedData[index];
            updateDatabase(element, element.layerId)
        }
        setProgress('Færdig med at indhente data!')
    }, [fetchedData])
setTimeout(() => {
    setProgress('')
}, 6000);
  return (
    <p id='progressP'>{progress}</p>
  )
}

export default CheckFetch