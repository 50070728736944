import React, { useEffect, useState } from 'react'
import axios from "axios";
import supabase from "../../supabase.js"
import { NavLink } from 'react-router-dom';
import EventCards from '../EventCards/EventCards.jsx';

const cityList = [
    "Aabenraa", "Aabybro", "Aakirkeby", "Aalborg", "Aalborg SV", "Aalborg SØ", "Aalborg Øst", "Aalestrup",
    "Aarhus C", "Aarhus N", "Aarhus V", "Aars", "Aarup", "Agedrup", "Agerbæk", "Albertslund", "Allerød",
    "Allinge", "Allingåbro", "Ansager", "Arden", "Asnæs", "Assens", "Augustenborg", "Aulum", "Auning",
    "Bagsværd", "Ballerup", "Beder", "Billund", "Bindslev", "Birkerød", "Bjerringbro", "Bjæverskov", "Blokhus",
    "Blåvand", "Bogense", "Bording", "Borup", "Brabrand", "Bramming", "Brande", "Bredebro", "Bredsten",
    "Broager", "Broby", "Brovst", "Brædstrup", "Brøndby", "Brøndby Strand", "Brønderslev", "Brønshøj", "Brørup",
    "Børkop", "Charlottenlund", "Christiansfeld", "Dianalund", "Dragør", "Dronninglund", "Dyssegård", "Ebeltoft",
    "Egtved", "Egå", "Ejby", "Engesvang", "Esbjerg", "Esbjerg N", "Esbjerg V", "Esbjerg Ø", "Espergærde",
    "Faaborg", "Fanø", "Farsø", "Farum", "Faxe", "Faxe Ladeplads", "Fjerritslev", "Fredensborg", "Frederiksberg",
    "Frederikshavn", "Frederikssund", "Frederiksværk", "Fuglebjerg", "Fårevejle", "Fårvang", "Gadstrup", "Galten",
    "Gentofte", "Gilleleje", "Gistrup", "Give", "Gjerlev J", "Glamsbjerg", "Glesborg", "Glostrup", "Glumsø",
    "Gram", "Grenaa", "Greve", "Grindsted", "Gråsten", "Græsted", "Gudhjem", "Gørlev", "Haarby", "Haderslev",
    "Hadsten", "Hadsund", "Hals", "Hammel", "Hanstholm", "Hasle", "Haslev", "Hasselager", "Havdrup", "Hedehusene",
    "Hedensted", "Hellerup", "Helsinge", "Helsingør", "Herfølge", "Herlev", "Herning", "Hesselager", "Hillerød",
    "Hinnerup", "Hirtshals", "Hjallerup", "Hjortshøj", "Hjørring", "Hobro", "Holbæk", "Holmegaard", "Holstebro",
    "Holsted", "Holte", "Hornbæk", "Hornslet", "Horsens", "Humble", "Humlebæk", "Hundested", "Hurup Thy", "Hvalsø",
    "Hvide Sande", "Hvidovre", "Hårlev", "Højbjerg", "Højby", "Høje Taastrup", "Højslev", "Høng", "Hørning",
    "Hørsholm", "Hørve", "Ikast", "Ishøj", "Jelling", "Juelsminde", "Jyderup", "Jyllinge", "Jægerspris",
    "Kalundborg", "Karise", "Karlslunde", "Karup J", "Kastrup", "Kerteminde", "Kibæk", "Kirke Hyllinge", "Kirke Såby",
    "Kjellerup", "Klampenborg", "Klarup", "Knebel", "Kokkedal", "Kolding", "Kolind", "Kongens Lyngby", "Korsør",
    "Kruså", "København", "København C", "Køge", "Langeskov", "Langå", "Lejre", "Lemvig", "Lille Skensved",
    "Lundby", "Lynge", "Lystrup", "Læsø", "Løgstør", "Løgumkloster", "Løkken", "Løsning", "Malling", "Mariager",
    "Maribo", "Marstal", "Middelfart", "Munkebo", "Måløv", "Møldrup", "Mørke", "Nakskov", "Nexø", "Nibe",
    "Nivå", "Nordborg", "Nordhavn", "Nyborg", "Nykøbing F", "Nykøbing M", "Nykøbing Sj", "Nysted", "Nærum",
    "Næstved", "Nørager", "Nørre Aaby", "Nørre Alslev", "Nørre Nebel", "Nørresundby", "Odder", "Odense", "Odense M",
    "Odense N", "Odense NV", "Odense NØ", "Odense S", "Odense SV", "Odense SØ", "Odense V", "Oksbøl",
    "Otterup", "Padborg", "Pandrup", "Præstø", "Randers C", "Randers NV", "Randers NØ", "Randers SV", "Randers SØ",
    "Regstrup", "Ribe", "Ringe", "Ringkøbing", "Ringsted", "Risskov", "Risskov Ø", "Roskilde", "Roslev", "Rudkøbing",
    "Rungsted Kyst", "Ry", "Ryomgård", "Rødby", "Rødding", "Rødekro", "Rødovre", "Rønde", "Rønne", "Rønnede",
    "Sabro", "Sakskøbing", "Samsø", "Silkeborg", "Sindal", "Skagen", "Skals", "Skanderborg", "Skibby", "Skive",
    "Skjern", "Skovlunde", "Skælskør", "Skærbæk", "Skævinge", "Skødstrup", "Skørping", "Slagelse", "Slangerup",
    "Smørum", "Snedsted", "Solbjerg", "Solrød Strand", "Sorø", "Spentrup", "Spjald", "Spøttrup", "Stege", "Stenlille",
    "Stenløse", "Store Heddinge", "Storvorde", "Struer", "Stubbekøbing", "Støvring", "Suldrup", "Sunds", "Svendborg",
    "Svenstrup J", "Svinninge", "Sydals", "Sæby", "Søborg", "Sønder Omme", "Sønderborg", "Søndersø", "Taastrup",
    "Tappernøje", "Tarm", "Terndrup", "Them", "Thisted", "Thyborøn", "Tilst", "Tinglev", "Tjele", "Toftlund",
    "Tommerup", "Tranbjerg J", "Tranekær", "Trige", "Trustrup", "Tune", "Tølløse", "Tønder", "Tørring", "Ulfborg",
    "Ullerslev", "Vadum", "Valby", "Vallensbæk", "Vallensbæk Strand", "Vamdrup", "Vanløse", "Varde", "Vedbæk", "Vejby",
    "Vejen", "Vejle", "Vejle Øst", "Vemmelev", "Vesløs", "Vestbjerg", "Vester Skerninge", "Viborg", "Viby J",
    "Viby Sjælland", "Videbæk", "Vig", "Vildbjerg", "Vinderup", "Vinterbyøster", "Virum", "Vissenbjerg", "Vodskov",
    "Vojens", "Vordingborg", "Vrå", "Væggerløse", "Værløse", "Ærøskøbing", "Åbyhøj", "Ålbæk", "Ålsgårde", "Årslev",
    "Ølgod", "Ølsted", "Ølstykke", "Ørbæk", "Ørsted", "Østbirk", "Øster Assels"
  ];

  function FindCity(text,cityList) {
    // Reverse the entire text
    const reversedText = text?.split('')?.reverse()?.join('');
   
    // Extract the part until the first space (or entire string if no space found)
    const firstSpaceIndex = reversedText?.indexOf(' ');
    const extractedPart = firstSpaceIndex !== -1 ? reversedText?.substring(0, firstSpaceIndex) : reversedText;
   
    // Remove numbers and '#' from the extracted part
    const cleanedExtractedPart = extractedPart?.replace(/[0-9#\s]/g, '');
   
    // Reverse the cleaned extracted part back to its original form
    const originalExtractedPart = cleanedExtractedPart?.split('')?.reverse()?.join('');
   
    
   
    // Check if the extracted part is in the allowed words list
    if (cityList?.some(item => originalExtractedPart?.includes(item))) {
      return originalExtractedPart;
    } else {
        console.log(originalExtractedPart);
      return originalExtractedPart;
    }
     }
function SupaFetch(props) {
    const [alarmer, setAlarmer] = useState([])
    useEffect(() => {
        const fetchAlarms = async () => {
          let { data: echoAlarmer, error } = await supabase
      .from('echoAlarmer')
      .select('*')
      .eq('dato', props.date)

      echoAlarmer.sort((a, b) => {
        const timeA = new Date(`2000-01-01T${a.tid}`);
        const timeB = new Date(`2000-01-01T${b.tid}`);
        return timeB - timeA;
      });

      setAlarmer(echoAlarmer)
        }      

        fetchAlarms()
          }, [props.date]);
  return (
    <>
    {alarmer ? (
        <div>
          {alarmer.map((item, index) => (
            <NavLink to={`/alarm/${item.id}`}>
            <EventCards 
            color={'gray'}
            time={item.tid}
            event={item.melding}
            city={item.placering ? FindCity(item.placering) : "Ukendt"}
            />
            </NavLink>
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
    
  )
}

export default SupaFetch