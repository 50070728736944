import React, { useEffect, useState } from 'react';
import supabase from "../../supabase.js"

function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

function descriptionClean(inputText) {
    const regex = /Førstemelding:\s*(.*?)\s*Station/g;
    const matches = regex.exec(inputText);
  
    if (matches && matches.length >= 2) {
      const extractedText = matches[1];
      const withoutSpaces = extractedText.replace(/\s+/g, ''); // Remove all spaces
  
      return withoutSpaces;
    }
  
    return "Not found";
  }
  
  function TimeClean(inputText) {
    const timePart = inputText.split(' ')[1]; // Splitting to get the time part
    const hours = parseInt(timePart.split(':')[0], 10); // Extracting hours as an integer
    const minutes = timePart.split(':')[1]; // Extracting minutes

    // Adding 2 to the hours and ensuring it's within the 24-hour range
    const adjustedHours = (hours + 2) % 24;

    // Formatting the adjusted time
    const adjustedTime = `${adjustedHours.toString().padStart(2, '0')}:${minutes}`;

    return adjustedTime;
}
  
  function extractStation(inputString) {
    const regex = /Station:(.*)/i;
    const match = inputString.match(regex);
  
    if (match) {
      return match[1].trim();
    } else {
      return "";
    }
  }
  
  function formatDate(inputDate) {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const dateObj = new Date(inputDate);
    const day = dateObj.getUTCDate().toString().padStart(2, '0');
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month as it is zero-indexed
    const year = dateObj.getUTCFullYear();
  
    return `${year}-${month}-${day}`;
  }
  
  async function newRecord(time, date, id, station, alarm) {
    
  const { data, error } = await supabase
  .from('echoAlarmer')
  .insert([
    { 
      tid: time, 
      dato: date,
      melding: alarm,  
      native_id: id,
      station: station, 
      kilder: '["Odin"]', 
    },
  ])
  .select()
  
  }
  

function MyComponent() {
    const [feedData, setFeedData] = useState([]);
    const [supaData, setSupaData] = useState([]);
    const rssFeedUrl = 'https://api.rss2json.com/v1/api.json?rss_url=http://www.odin.dk/RSS/RSS.aspx?beredskabsID=0000';

    const fetchFeedData = async () => {
        try {
            const response = await fetch(rssFeedUrl);
            const data = await response.json();
            setFeedData(data.items);
        } catch (error) {
            console.error('Error fetching feed data:', error);
        }
    };

   async function getDatabase() {
        
let { data: echoAlarmer, error } = await supabase
.from('echoAlarmer')
.select('native_id')
.eq('dato', getFormattedDate())
if (error) {
    console.log('get error', error);
}
setSupaData(echoAlarmer)
console.log(echoAlarmer);
    }
    useEffect(() => {
        fetchFeedData();
        console.log(feedData);
        getDatabase()

    }, []);

    useEffect(() => {
        let tempArray=[]
for (let index = 0; index < feedData.length; index++) {
    const element = feedData[index];
    if (supaData.length === 0) {
      console.log('All in');
        newRecord(TimeClean(element.pubDate), formatDate(element.pubDate), element.guid, extractStation(element.description), descriptionClean(element.description)) 
    }else{
        if (!supaData.includes(element.guid)) {
            console.log('Single in');
            newRecord(TimeClean(element.pubDate), formatDate(element.pubDate), element.guid, extractStation(element.description), descriptionClean(element.description)) 
        }else{
           console.log('Ignore'); 
        }
    }
}


    }, [supaData])
   

    return (
        <div>
            
           
        </div>
    );
}

export default MyComponent;
