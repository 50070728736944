import React, { useEffect } from 'react'
import { LoadingStyle } from './Loading.style';
import Dashboard from '../Dashboard/Dashboard';
import { useNavigate } from 'react-router-dom';
import CheckFetch from '../../Components/RssToJson/CheckFetch';


function LoadingScreen() {
    const navigate = useNavigate();
    useEffect(() => {
        const loadingBar = document.querySelector('.loadingbar');
        const loadingBox = document.querySelector('.loadingBox');
        const splashScreen = document.querySelector('.splashScreen');
        const loadingCircle = document.querySelector('.loadingCircle');
        const body = document.querySelector('body');
        
        setTimeout(() => {
            loadingBar.style.transition = 'left 3s';
            loadingBar.style.left = '0';
        }, 1500);
        
        setTimeout(() => {
            loadingBox.style.transition = 'opacity 1s';
            loadingBox.style.opacity = '1';
        }, 500);
        
        setTimeout(() => {
            splashScreen.style.transition = 'top 1.5s';
            splashScreen.style.top = '-100vh';
            loadingCircle.style.transition = 'opacity 0.5s';
            loadingCircle.style.opacity = '0';
        }, 4500);
        
        setTimeout(() => {
            body.classList.add("visibleSplash");
        }, 5000);

        setTimeout(() => {
            splashScreen.style.display = 'none';
            navigate("/dashboard");
        }, 6000);
        
    }, [])

  return (
    <LoadingStyle>
        <div class="splashBody">
	<div class="splashScreen">
		<div class="loadingContainer">

			<div class="loadingBox">
				<img src="https://vignette.wikia.nocookie.net/borderlands/images/4/42/Vault_logo.png/revision/latest?cb=20100114181536" class="splashLogo" />
				<CheckFetch />
                <div class="loadingBarContainer">
					<div class="loadingbar"></div>
                    
				</div>

				<div class="loadingCircle">
					<div class="circleOuter"></div>
					<div class="circleLoader"></div>
				</div>
			</div>


		</div>
	</div>
	<div class="splashBehind">
		
	</div>

</div>

    </LoadingStyle>
    
  )
}

export default LoadingScreen