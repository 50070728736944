import React, { useEffect, useState } from 'react'
import { SingleItemStyle } from './SingleItem.style'
import TrafficMap from '../../Components/TrafficMap/TrafficMap'
import { NavLink, Navigate, useParams } from 'react-router-dom'
import JustMap from '../../Components/TrafficMap/JustMap'
import axios from 'axios'
import supabase from "../../supabase.js"
import DelConfirm from '../../Components/DelConfirm/DelConfirm'

function SingleItem() {
    
    const { id } = useParams()
    const [gps, setGps] = useState(true)
    const [generelt, setGenerelt] = useState(false)
    const [vejr, setVejr] = useState(false)
    const [Lat, setLat] = useState(56.12993473363111)
    const [Lng, setLng] = useState(8.897034010297991)
    const API_KEY = 'AIzaSyAfPOw-RMPwGU-Gt96Ur0OmVwxgNBbyC5Q';
    const [address, setAddress] = useState()
    const [weather, setWeather] = useState()
    const [alarm, setAlarm] = useState()
    const [deleted, setDeleted] = useState(false)
const currentHour = new Date().getHours()

console.log('current hour', currentHour);

function visibility(str) {
    let vis;

    switch (true) {
      case str > 10000:
        vis = "Fremragende";
        break;
      case str > 5000:
        vis = "Meget God";
        break;
      case str > 3000:
        vis = "God";
        break;
      case str > 1000:
        vis = "Moderat";
        break;
      case str > 500:
        vis = "Dårlig";
        break;
      case str > 200:
        vis = "Meget Dårlig";
        break;
      case str > 0:
        vis = "Elendig";
        break;
      default:
        vis = "Nul Synlighed";
    }
  
    return vis;
}
    const handleDelete = async () => {
        
// const { error } = await supabase
// .from('echoAlarmer')
// .delete()
// .eq('id', id)

        console.log('Item deleted!');   
        setDeleted(true)

      };

      useEffect(() => {
        const fetchAlarms = async () => {
            let { data: echoAlarmer, error } = await supabase
        .from('echoAlarmer')
        .select('*')
        .eq('id', id )
        setAlarm(echoAlarmer[0])
        setLat(echoAlarmer[0].lat)
        setLng(echoAlarmer[0].long)
          }
          fetchAlarms() 
      }, [id])
      console.log(alarm);
      useEffect(() => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${Lat},${Lng}&key=${API_KEY}`;
        const url2 = `https://api.open-meteo.com/v1/forecast?latitude=${Lat}&longitude=${Lng}&hourly=temperature_2m,precipitation,apparent_temperature,visibility,windspeed_10m,winddirection_10m,windgusts_10m,is_day&daily=uv_index_max&windspeed_unit=ms&timezone=Europe%2FBerlin&forecast_days=1`;
    
        axios.get(url)
          .then(response => {
            // Handle the response data here
            console.log(response.data);
            setAddress(response.data)
          })
          .catch(error => {
            // Handle errors here
            console.error(error);
          });

          axios.get(url2)
          .then(response => {
            // Handle the response data here
            console.log(response.data);
            setWeather(response.data)
          })
          .catch(error => {
            // Handle errors here
            console.error(error);
          });
      }, [Lat, Lng]);
console.log(document.getElementById('mapLat'));
console.log(address ? address?.results : 0);
if (deleted) {
    return <Navigate to={'/dashboard'}/>
  }
  return (
    <SingleItemStyle>
           <article>
            <NavLink to={"/dashboard"}>{"<--"} Alarmer</NavLink>
            <h1>{alarm ? alarm.melding : 'Loading...'}</h1>
            <div>
                <p>kilder</p>
                <p><strong>{alarm ? alarm.kilder === null ? "Ingen kilder endnu" : alarm.kilder : 'Loading...'}</strong></p>
            </div>
            <div><p>Image Grid</p></div>
           </article>
           <article>
            <div className='topBtnDiv'>
              <h2>Kort om alarmen</h2>
              <div>
                            <NavLink to={`/updatealarm/${id}`}>Ændre</NavLink>
                            <button>Link 2 alarmer</button>
            <DelConfirm  
            date={alarm ? alarm.dato : null}
            onDelete={handleDelete}/>
                </div>  
            </div>

            <p>Første melding: {alarm ? alarm.tid : 'Loading..'} {alarm ? alarm.dato : 'Loading..'}</p>
            {/* <input type="datetime-local" name="" id="" /> */}
            <p></p>
            <div className='btnDiv'>
                <button className={gps ? 'active' : 'notActive'} onClick={() => {
                    setGps(true)
                    setGenerelt(false)
                    setVejr(false)
                }}>Sted</button>
                <button className={generelt ? 'active' : 'notActive'} onClick={() => {
                    setGps(false)
                    setGenerelt(true)
                    setVejr(false)
                }}>Generelt</button>
                <button className={vejr ? 'active' : 'notActive'} onClick={() => {
                    setGps(false)
                    setGenerelt(false)
                    setVejr(true)
                }}>Vejret</button>
            </div>
            
                <form action="">
                    
                <div className='gpsData' style={gps ? {display: 'block'} : {display: 'none'}}>
                <p>GPS data</p>
                <h2>Land</h2>
                <input type="text" value={address ? address?.results[address?.results.length - 1]?.address_components[0]?.long_name : 'Ukendt'}  placeholder='Land'/>
                <h2>Region</h2>
                <input type="text" value={address ? address?.results[address?.results.length - 2]?.address_components[0]?.long_name : 'Ukendt'} placeholder='Region'/>
                <h2>By</h2>
                <input type="text" value={address ? address?.results[0]?.address_components[2]?.long_name : 'Ukendt'}  placeholder='By'/>
                <div className='street'>
                    <div>
                                     <h2>Gade/vejnavn</h2>
                <input type="text" value={address ? address?.results[address?.results.length.address?.results.length + 1]?.address_components[1]?.long_name : 'Ukendt'}  placeholder='Gade/vejnavn'/>   
                    </div>

                <div>
                                  <h2>Nr.</h2>
                <input type="text" value={address ? address?.results[0]?.address_components[0]?.long_name : 'Ukendt'} placeholder='Nr.'/>  
                </div>

                </div>
                <div className='latLng'>
                    <div>
                  <h2>Lat</h2>
                <input type="number" id='inputLat'  placeholder='Lat' value={Lat} onChange={(e) => {setLat(e.target.value)}}/> 
                    </div>
                    <div>
                                       <h2>Long</h2>
                <input type="number" id='inputLong' placeholder='Long' value={Lng} onChange={(e) => {setLng(e.target.value)}}/> 
                    </div>

                </div>              
            </div> 

            <div className='generelt' style={generelt ? {display: 'block'} : {display: 'none'}}>
                <p>Om opgaven</p>
                
                <input type="text" value={alarm ? alarm.melding : 'Loading...'} placeholder='Første melding'/>
                <h2>Station</h2>
                <input type="text" value={alarm ? alarm.station : 'Loading...'} placeholder='Station'/>
                <h2>Bemærkninger fra operatør</h2>
                <input type="text"  placeholder='Bemærkninger fra operatør'/>
                <h2>Beskrivelse</h2>
                <textarea name="" id="" cols="60" rows="20" value={alarm ? alarm.beskrivelse : 'Loading...'}></textarea>
            </div> 

            <div className='vejr' style={vejr ? {display: 'block'} : {display: 'none'}}>
                <p>Vejret på/omkring stedet</p>
                <div className='vind'>
<div>
<h2>Vindhastighed</h2>
                <input type="text" value={weather ? weather.hourly.windspeed_10m[currentHour] + ' m/s' : 'Ingen data'} placeholder='Vindhatighed'/>
</div>
<div>
<h2>Vindstød</h2>
                <input type="text" value={weather ? weather.hourly.windgusts_10m[currentHour] + ' m/s' : 'Ingen data'} placeholder='Vindstød'/>
</div>
                </div>   
                <h2>Regn den næste time</h2>
                <input type="text" value={weather ? weather.hourly.precipitation[currentHour] + ' mm' : 'Ingen data'} placeholder='Regn den næste time'/>
                <h2>Sigtbarhed</h2>
                <input type="text" value={weather ? visibility(weather.hourly.visibility[currentHour]) : 'Ingen data'} placeholder='Sigtbarhed'/>
                <h2>Vejrvarsler</h2>
                <input type="text" value={alarm ? alarm.varsler : 'Loading...'} placeholder='Vejrvarsler'/>
         
            </div> 
                </form>

           </article>
           <article style={{zIndex: 0}} onClick={() => {
            setLat(parseFloat(document.getElementById('mapLat')?.innerText.replace(/,/g, '.')))
            setLng(parseFloat(document.getElementById('mapLong')?.innerText.replace(/,/g, '.')))
            setTimeout(() => {
                
                console.log('click');
              }, 1000);
              
            
           }}>


                <JustMap lat={alarm ? alarm.lat : Lat} lng={alarm ? alarm.long : Lng}/>
           </article>
    </SingleItemStyle>
  )
}

export default SingleItem