import React, { useState } from 'react';

function DelConfirm({ onDelete,date }) {
  const [confirmationText, setConfirmationText] = useState('');

  const handleDelete = () => {
    const confirmationMessage = 'Skriv dato for alarmen for at slette';
    const userConfirmation = window.prompt(confirmationMessage);

    if (userConfirmation && userConfirmation.trim().toLowerCase() === date) {
      onDelete();
    } else {
      window.alert('Sletning mislykkedes');
    }
  };

  return (
    <button onClick={handleDelete}>
      Delete
    </button>
  );
}

export default DelConfirm;
