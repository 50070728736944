import styled from "styled-components";

export const TrafficMapStyle = styled.div`
z-index: 0;
img{
    height: 30vh;
    width: 19vw;
}

.filterDiv{
    width: 40%;
    display: flex;
label{
    width: 80%;
}
}

.restDiv{
    img{
        height: 5vh;
        width: auto;
    }
}
`