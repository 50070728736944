import React, { useEffect, useState } from 'react'
import supabase from "../../supabase.js"

function isLessThanTwoMinutesFromNow(targetDateString) {
  const targetDate = new Date(targetDateString);
  const currentTime = new Date();
  const timeDifferenceInMilliseconds = targetDate - currentTime;

  const twoMinutesInMilliseconds = 4 * 60 * 1000; // 2 minutes in milliseconds

  return timeDifferenceInMilliseconds > twoMinutesInMilliseconds;
}

const knownRoads = [
  {
    "AMX_SE04525": {
      "Navn": "Aalborg, Hobrovej"
    },
    "AMX_SE04710": {
      "Navn": "Aalborg, Limfjordstunnelen"
    },
    "AM3_SE4289": {
      "Navn": "Aalborg, Limfjordsbroen"
    },
    "AMX_SE02992": {
      "Navn": "Aalborg, Motervejen ved stationen"
    },
    "AMX_SE02993": {
      "Navn": "Aalborg, Motervejen ved stationen"
    },
    "AM3_SE4932": {
      "Navn": "Aalborg, Motervejen udenfor techcollege"
    },
    "AM3_SE5037": {
      "Navn": "Aalborg, Motervejen udenfor techcollege"
    },
    "AMX_SE04707": {
      "Navn": "Aalborg, Limfjordtunnelen"
    },
    "AM3_SE4283": {
      "Navn": "Aalborg, vesterbro"
    },
    "AM3_SE4282": {
      "Navn": "Alborg, Vesterbro"
    },
    "AM3_SE5494": {
      "Navn": "Holstebro, Viborgvej"
    },
    "AMX_SE04288": {
      "Navn": "Lemvig, Lemvigvej"
    },
    "AMX_SE02572": {
      "Navn": "Nørresundby, Nørre utterrup torv"
    },
    "AMX_SE02575": {
      "Navn": "Nørresundby, Nørre utterrup torv"
    },
    "AM3_SE4273": {
      "Navn": "Nørresundby, Limfjordsbroen"
    },
    "AM3_SE4290": {
      "Navn": "Nørresundby, Ved computerbutikken"
    },
    "AM3_SE4291": {
      "Navn": "Nørresundby, Ved computerbutikken"
    },
    "AM3_SE4930": {
      "Navn": "Nørresundby, Ud mod motorvejen"
    },
    "AMX_SE04122": {
      "Navn": "Skagen, Frederikshavnvej"
    },
    "AMX_SE04123": {
      "Navn": "Skagen, Frederikshavnvej"
    },
    "AMX_SE03143": {
      "Navn": "Skalborg, Udenfor Ikea"
    },
    "AMX_SE03170": {
      "Navn": "Skalborg, Udenfor Ikea"
    },
    "AMX_SE02176": {
      "Navn": "Snejbjerg, Albækvej"
    }
  }
  
]
const KnownRoadsObject = knownRoads[0]

function CheckTraffic() {
const [data, setData] = useState()
const [lastFetch, setLastFetch] = useState()
console.log(knownRoads);
async function GetData(params) {
  
let { data: echoFetch1, error1 } = await supabase
.from('echoFetch')
.select('last_fetch')
.eq('id', 8)
setLastFetch(echoFetch1[0].last_fetch)
console.log(echoFetch1[0].last_fetch);

let { data: echoFetch, error } = await supabase
.from('echoFetch')
.select('serviceData')
.eq('id', 8)

setData(echoFetch[0].serviceData)
console.log(echoFetch[0].serviceData);
}
async function TrafficLookUp() {
  for (const key in data) {
    if (data[key][0] === 4) {
      if (KnownRoadsObject.hasOwnProperty(key)) {
          if (isLessThanTwoMinutesFromNow(lastFetch)) {
            console.log(`Found key: ${key}, value: ${KnownRoadsObject[key].Navn}`);

          const { data, error } = await supabase
          .from('echoNotify')
          .insert([
            { 
              notification: `Kø dannelse ved/i ${KnownRoadsObject[key].Navn}`
            }
          ])
          .select()
        
          }else{
            console.log('To soon');
          }
          
        
        
      } else {
        console.log(false);
      }
    }
  }
}


useEffect(() => {
  GetData()
  TrafficLookUp()
}, [])


    return (
        <div>
          <p>Check the console for results.</p>
        </div>
      );
    }

export default CheckTraffic