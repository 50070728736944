import styled from "styled-components";

export const CreateSingleItemStyle = styled.section`
display: grid;
grid-template-columns: repeat(2, 1fr);
height: 90vh;
width: 100%;
overflow: auto;

a{
    text-decoration: none;
    color: gray;
    font-size: 20px;
    margin-top: 1vh;
}

.btnDiv{
    width: 85%;
    background-color: darkgray;
    border-radius: 20px;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .notActive{
        font-size: 20px;
        width: 75%;
        color: gray;
        background-color: darkgray;
        border: none;
        border-radius: 50px;
        padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    }
}

.newAlarm{
    margin-top: 1vh;
    background-color: green;
        width: 100%;
        border-radius: 20px;
        border-color: black;
        border-style: solid;
        padding-top: 2vh;
        padding-bottom: 2vh;
        padding-left: 35%;
        padding-right: 35%;
        color: white;
        font-size: 20px;
}

.active{
    font-size: 20px;
        width: 75%;
        background-color: gray;
        color: white;
        border: none;
        border-radius: 50px;
        padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh; 
}
.gpsData{
    display: grid;
grid-template-columns: 1fr;
margin: 0;
padding: 0;
}

input{
    width: 90%;
    height: 5vh;
    padding-left: 1vw;
    border-radius: 20px;
    font-size: 20px;
}
.street, .latLng{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 95%;
    gap: 1vw;
}

.vind{
    display: grid;
    grid-template-columns: 1fr;
    width: 95%;
    gap: 1vw;  
}
`