import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EventCards from '../EventCards/EventCards';
import { NavLink } from 'react-router-dom';
import supabase from "../../supabase.js"

function descriptionClean(inputText) {
  const regex = /Førstemelding:\s*(.*?)\s*Station/g;
  const matches = regex.exec(inputText);

  if (matches && matches.length >= 2) {
    const extractedText = matches[1];
    const withoutSpaces = extractedText.replace(/\s+/g, ''); // Remove all spaces

    return withoutSpaces;
  }

  return "Not found";
}

function TimeClean(inputText) {
  const regex = /2023\s*(.*?)\s*GMT/g;
  const matches = regex.exec(inputText);

  if (matches && matches.length >= 2) {
    const extractedText = matches[1];
    const withoutSpaces = extractedText.replace(/\s+/g, ''); // Remove all spaces

    const [hoursStr, minutesStr, secondsStr] = withoutSpaces.split(':');
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);

  const date = new Date(0); // Create a new date object at the UNIX epoch (01 Jan 1970 00:00:00 UTC)
  date.setUTCHours(hours + 2);
  date.setUTCMinutes(minutes);
  date.setUTCSeconds(seconds);

  const result = date.toISOString().substr(11, 8);
  return result
  }

  return "Not found";
}

function extractStation(inputString) {
  const regex = /Station:(.*)/i;
  const match = inputString.match(regex);

  if (match) {
    return match[1].trim();
  } else {
    return "";
  }
}

function formatDate(inputDate) {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const dateObj = new Date(inputDate);
  const day = dateObj.getUTCDate().toString().padStart(2, '0');
  const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month as it is zero-indexed
  const year = dateObj.getUTCFullYear();

  return `${year}-${month}-${day}`;
}

async function newRecord(time, date, station, alarm) {
  
const { data, error } = await supabase
.from('echoAlarmer')
.insert([
  { 
    tid: time, 
    dato: date,
    melding: alarm,  
    station: station, 
    kilder: '["Odin"]', 
  },
])
.select()

}

const today = new Date();
const year = today.getUTCFullYear();
const month = String(today.getUTCMonth() + 1).padStart(2, "0");
const day = String(today.getUTCDate()).padStart(2, "0");

const RssToJson = () => {
  const [supaData, setSupaData] = useState()
  const [feedData, setFeedData] = useState([
    {
        "title": "Hovedstadens Beredskab",
        "description": "Førstemelding: Brandalarm Station: Christianshavn",
        "pubDate": "Thu, 03 Aug 2023 18:16:12 GMT"
    },
    {
        "title": "TrekantBrand",
        "description": "Førstemelding: Eftersyn Station: Fredericia",
        "pubDate": "Thu, 03 Aug 2023 18:20:56 GMT"
    },
    {
        "title": "Nordjyllands Beredskab",
        "description": "Førstemelding: ISL-Forespørgsel Station: Hobro",
        "pubDate": "Thu, 03 Aug 2023 18:14:50 GMT"
    },
    {
        "title": "Slagelse Brand og Redning Sorø",
        "description": "Førstemelding: Naturbrand-Skov/Plantage Station: Sorø BV",
        "pubDate": "Thu, 03 Aug 2023 18:13:55 GMT"
    },
    {
        "title": "Beredskab Øst",
        "description": "Førstemelding: ISL-Eftersyn  Station: Gentofte",
        "pubDate": "Thu, 03 Aug 2023 17:58:09 GMT"
    },
    {
        "title": "Hovedstadens Beredskab",
        "description": "Førstemelding: Brandalarm Station: Glostrup",
        "pubDate": "Thu, 03 Aug 2023 17:23:38 GMT"
    },
    {
        "title": "Nordjyllands Beredskab",
        "description": "Førstemelding: Bygn.brand-Garage, fritliggend Station: Nykøbing Mors",
        "pubDate": "Thu, 03 Aug 2023 17:23:36 GMT"
    },
    {
        "title": "Sønderborg Brand & Redning",
        "description": "Førstemelding: ISL-Forespørgsel Station: Sønderborg",
        "pubDate": "Thu, 03 Aug 2023 17:04:22 GMT"
    },
    {
        "title": "Brand & Redning MidtVest",
        "description": "Førstemelding: Bygn.brand-Industribygning Station: Aulum/Ørnhøj",
        "pubDate": "Thu, 03 Aug 2023 17:04:03 GMT"
    },
    {
        "title": "Vestsjællands Brandvæsen",
        "description": "Førstemelding: Bygn.brand-Etageejendom-Lejlighed\n Station: Snertinge",
        "pubDate": "Thu, 03 Aug 2023 16:58:00 GMT"
    },
    {
        "title": "Hovedstadens Beredskab",
        "description": "Førstemelding: Brandalarm Station: Hovedbrandstationen",
        "pubDate": "Thu, 03 Aug 2023 16:37:12 GMT"
    },
    {
        "title": "Brand & Redning Sønderjylland",
        "description": "Førstemelding: FUH-Fastklemte LASTBIL/BUS Station: Kliplev",
        "pubDate": "Thu, 03 Aug 2023 16:32:25 GMT"
    },
    {
        "title": "Midtjysk Brand & Redning",
        "description": "Førstemelding: Min. forurening-Oliefilm på va Station: Silkeborg",
        "pubDate": "Thu, 03 Aug 2023 16:29:37 GMT"
    },
    {
        "title": "Hovedstadens Beredskab",
        "description": "Førstemelding: Brandalarm Station: Christianshavn",
        "pubDate": "Thu, 03 Aug 2023 16:13:36 GMT"
    },
    {
        "title": "Hovedstadens Beredskab",
        "description": "Førstemelding: Brandalarm Station: Vesterbro",
        "pubDate": "Thu, 03 Aug 2023 16:10:41 GMT"
    },
    {
        "title": "Tårnby Brandvæsen",
        "description": "Førstemelding: Brandalarm Station: Tårnby",
        "pubDate": "Thu, 03 Aug 2023 15:33:56 GMT"
    },
    {
        "title": "Hovedstadens Beredskab",
        "description": "Førstemelding: Brandalarm Station: Hvidovre",
        "pubDate": "Thu, 03 Aug 2023 15:31:47 GMT"
    },
    {
        "title": "Hovedstadens Beredskab",
        "description": "Førstemelding: Min. forurening-v/FUH  Station: Christianshavn",
        "pubDate": "Thu, 03 Aug 2023 15:26:49 GMT"
    },
    {
        "title": "Beredskab Øst",
        "description": "Førstemelding: Brandalarm Station: Lyngby",
        "pubDate": "Thu, 03 Aug 2023 15:13:02 GMT"
    },
    {
        "title": "Beredskab Øst",
        "description": "Førstemelding: Min. forurening-Mindre spild  Station: Gentofte",
        "pubDate": "Thu, 03 Aug 2023 15:10:34 GMT"
    }
]);

  useEffect(() => {
    const fetchRssFeed = async () => {
      const feedUrl = 'http://www.odin.dk/RSS/RSS.aspx?beredskabsID=0000'; // Replace with your RSS feed URL
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/'; // CORS proxy URL

      try {
        const response = await axios.get(proxyUrl + feedUrl);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data, 'text/xml');
        const items = xmlDoc.getElementsByTagName('item');
        const parsedData = Array.from(items).map((item) => {
          const title = item.getElementsByTagName('title')[0]?.textContent || '';
          const description = item.getElementsByTagName('description')[0]?.textContent || ''; // Change 'description' to 'description'
          const pubDate = item.getElementsByTagName('pubDate')[0]?.textContent || '';
          return { title, description, pubDate };
        });

        setFeedData(parsedData);
        sessionStorage.setItem('test2', JSON.stringify(parsedData))
        console.log(parsedData);
        
      } catch (error) {
        console.error('Error fetching RSS feed:', error);
      }
    };

    async function getTodaysAlarms() {
      

      let { data: echoAlarmer, error } = await supabase
      .from('echoAlarmer')
      .select('tid','melding')
      .eq('dato', `${year}-${month}-${day}`)
      setSupaData(echoAlarmer)

console.log(echoAlarmer);
    }

getTodaysAlarms()
  //  fetchRssFeed();
  }, []);
// if (supaData) {
//   for (let feedIndex = 0; feedIndex < feedData.length; feedIndex++) {
//     const feedElement = feedData[feedIndex];
//     let foundMatch = false;
  
//     for (let supaIndex = 0; supaIndex < supaData.length; supaIndex++) {
//       const supaElement = supaData[supaIndex];
//       if (
//         supaElement.tid === TimeClean(feedElement.pubDate) ||
//         supaElement.melding === descriptionClean(feedElement.description)
//       ) {
//         foundMatch = true;
//         break; // Exit the inner loop since we found a match
//       }
//     }
  
//     if (!foundMatch) {
//       console.log("Value not found in supaData:", feedElement);
//       newRecord(TimeClean(feedElement.pubDate), formatDate(feedElement.pubDate), extractStation(feedElement.description), descriptionClean(feedElement.description))
//     }
//   }
// }
  

  return (
    <div>
      {/* {feedData ? (
        <div>
          {feedData.map((item, index) => (
            <NavLink to={`/dashboard`}>
            <EventCards 
            color="blue"
            time={TimeClean(item.pubDate)}
            event={descriptionClean(item.description)}
            city={"Ukendt"}
            />
            </NavLink>
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )} */}
    </div>
  );
};

export default RssToJson;
