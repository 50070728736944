import { useEffect, useState } from 'react';
import AppRouter from './AppRouter/AppRouter';
import Header from './Components/Header/Header';
import Sidebar from './Components/Sidebar/Sidebar';
import { GlobalStyle } from './Global.style';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();
  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    // Update the state with the current URL whenever the location changes
    setCurrentURL(location.pathname);
    console.log(location.pathname);
  }, [location]);
  
  return (
    <div className="App">
      <GlobalStyle />

      
      
      {currentURL !== "/" ? <Header /> : <></>}
      <section className='main-container'>
      {currentURL !== "/" ? <Sidebar /> : <div></div>}
    <AppRouter />
      </section>

    </div>
  );
}

export default App;
