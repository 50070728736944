import React from 'react'
import { EventCardsStyle } from './EventCards.style'

function EventCards(props) {
  return (
    <EventCardsStyle>
          <span className="article-border" style={{backgroundColor: `${props.color}`}}></span>
    <div className="article-content">
      <p>Alarm</p>
      <h6>{props.event}</h6>
    </div>
    <div className="article-content">
      <p>Tidspunkt</p>
      <h6>{props.time}</h6>
    </div>
    <div className="article-content">
      <p>By</p>
      <h6>{props.city}</h6>
    </div>
    </EventCardsStyle>
  )
}

export default EventCards