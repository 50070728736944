import styled from "styled-components";

export const DashboardStyle = styled.section`
display: grid;
grid-template-columns: 20% 80%;
height: 94.5vh;
width: 100vw;
margin: 0;
padding: 0; 

a{
        text-decoration: none;
        color: black;
      }
      .newAlarm{
        display: block;
        text-align: center;
        text-decoration: none;
        background-color: green;
        width: 93%;
        border-radius: 20px;
        border-color: black;
        border-style: solid;
        padding-top: 1vh;
        padding-bottom: 1vh;
        margin-top: 1vh;
        color: white;
        font-size: 20px;
      }
.alarm-container{
  //  display: grid;
grid-template-columns: 1fr;
overflow: auto;
margin: 0;
padding: 0;

}

input{
    width: 50%;
    height: 3vh;
    border-radius: 20px;
}
`