import React, { useState } from 'react'
import EventCards from '../../Components/EventCards/EventCards'
import TrafficMap from '../../Components/TrafficMap/TrafficMap'
import RssToJson from '../../Components/RssToJson/RssToJson'
import { DashboardStyle } from './Dashboard.style'
import MailSlurpEmailList from '../../MailSlurp'
import SupaFetch from '../../Components/SupaFetch/SupaFetch'
import { NavLink } from 'react-router-dom'
import { LatLng } from 'leaflet'
import LatLngConverter from './Latlng'
import MailinatorComponent from '../../Components/RssToJson/MailinatorComponent'
import CheckFetch from '../../Components/RssToJson/CheckFetch'
import MyComponent from '../../Components/RssToJson/FetchOdin'

const today = new Date();
const year = today.getUTCFullYear();
const month = String(today.getUTCMonth() + 1).padStart(2, "0");
const day = String(today.getUTCDate()).padStart(2, "0");

function Dashboard() {
  const [alarmDate, setAlarmDate] = useState(`${year}-${month}-${day}`)
  const [showMap, setShowMap] = useState(false)
  console.log(alarmDate);


  return (
    <DashboardStyle>

      <div className="alarm-container">
        <input type="date" name="alarmDate" id="alarmDate" onChange={(e) => setAlarmDate(e.target.value)} />
                   {/* <MailSlurpEmailList date={alarmDate} /> */}
                   <SupaFetch 
                   date={alarmDate}
                   />
                   <RssToJson />
                   <MailinatorComponent />
                  <MyComponent />
                   <NavLink className='newAlarm' to={"/newalarm"}>Ny alarm</NavLink>
      </div>
      
    <TrafficMap date={alarmDate}/>
    </DashboardStyle>

  )
}

export default Dashboard