import React from 'react'
import { HeliCardStyle } from './HeliCard.style'

function HeliCard() {
  return (
    <HeliCardStyle>
    <div className='reqNr'>
      <p className='title'>Reg Nr.</p>
      <p className='text'><strong>LA-ONZ</strong></p>
    </div>
    
    <div className='type'>
      <p className='title'>Type</p>
      <p className='text'>EC35</p>
    </div>

    <div className='callsign'>
      <p className='title'>Callsign</p>
      <p className='text'>DOC86</p>
    </div>

    <div className='iLuften'>
      <p className='title'>I luften</p>
      <p className='text'>Ja</p>
    </div>

    <div className='sidsteFlyv'>
      <p className='title'>Sidste flyvning</p>
      <p className='text'>Nu</p>
    </div>
    </HeliCardStyle>

  )
}

export default HeliCard