import styled from "styled-components";

export const EventCardsStyle = styled.section`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      position: relative;
      border-radius: 20px;
      border: solid 1px black;
      width: 95%;
      overflow: auto;
      height: fit-content;
      margin-top: 1vh;
      


    .article-border {
      position: absolute;

      top: 0;
      left: -5px;
      width: 10px;
      height: 60%;
      margin-top: 4%;
      background-color: red;
    }

    .article-content {
      padding: 10px;

    }

    .article-content p,
    .article-content h6 {
      margin: 0;
    }

.map-container{
  height: 90vh;
  width: 97%;
  margin-left: 2vw;
  
}

`