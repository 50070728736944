import React, { useEffect, useState } from 'react'
import supabase from "../../supabase.js"

function CheckOnline() {
const [data, setData] = useState([])
const [updater, setUpdater] = useState()
console.log(sessionStorage.getItem('user'));


async function getData(params) {
    
let { data: echoUsers, error } = await supabase
.from('echoUsers')
.select('id,name,isonline,image_url')


const { data, error5 } = await supabase
  .from('echoUsers')
  .update({ isonline: true })
  .eq('email', sessionStorage.getItem('user'))
  .select()

const echoUsers2 = supabase.channel('custom-filter-channel')
  .on(
    'postgres_changes',
    { event: '*', schema: 'public', table: 'echoUsers', filter: 'isonline=eq.true' },
    (payload) => {
      console.log('Change received!', payload)
      if (sessionStorage.getItem('user') !== payload.new.email) {
        setData((prevData) => [...prevData, payload.new]); // Append payload to newData
      }
      
    }
  )
  .subscribe()

setData(echoUsers)
console.log(echoUsers);
}

useEffect(() => {
getData()
}, [])

useEffect(() => {
    getData()
    }, [updater])

    useEffect(() => {
        const interval = setInterval(() => {
          console.log('This will run every second!');
          getData()
        }, 60000);
        return () => clearInterval(interval);
      }, []);
  return (
    <div>
        <h6 style={{marginBottom: '1vh'}}>Online</h6>
{
    data?.map((user, index) => (
     index < data.length - 1 ? user.isonline ? <img style={{borderRadius: '50px', width: '3vw', marginLeft: '0.5vw', height: '5.5vh'}} key={index} src={user.image_url} alt="avatar" /> : null : null
    ))
}
    </div>
  )
}

export default CheckOnline