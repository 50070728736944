import { keyboardImplementationWrapper } from '@testing-library/user-event/dist/keyboard';
import React, { useState, useEffect } from 'react';
import EventCards from '../EventCards/EventCards';

const suffixList = ["vej", "gade", "vænge", "allé", "boulevard", "plads", "sti", "stræde", "terasse", "terrasse", "vejlede", "gården", "aveny", "stien", "søvej", "fjord", "øster", "vest", "nord", "syd", "promenade", "mole", "esplanade", "torv", "mark", "løkke", "holmen", "skov", "skoven", "hegnet", "engen", "eng", "vang", "kærret", "kær", "bjerg", "bjergene", "bjergvej", "bakke", "bakken", "alle", "alleen", "gård", "torvet", "holme", "lund", "vænget", "parken", "park", "by", "byen", "pladsen", "grunden", "grønningen", "høj", "jord", "have", "gadekær", "skole", "bjergs", "port", "have", "dal", "dam", "engs", "skovs", "holms", "jords", "højs", "søen", "stranden", "parkens", "broen", "enges", "vangs", "dals", "havens", "dammen", "enget", "ly", "byens", "kærrets", "lundens", "have", "plads", "park", "engs", "torvet", "skovs", "holms", "vejene", "gade", "vænge", "allé", "boulevard", "plads", "sti", "stræde", "terasse", "terrasse", "vejlede", "gården", "aveny", "stien", "søvej", "fjord", "øster", "vest", "nord", "syd", "promenade", "mole", "esplanade", "torv", "mark", "løkke", "holmen", "skov", "skoven", "hegnet", "engen", "eng", "vang", "kærret", "kær", "bjerg", "bjergene", "bjergvej", "bakke", "bakken", "alle", "alleen", "gård", "torvet", "holme", "lund", "vænget", "parken", "park", "by", "byen", "pladsen", "grunden", "grønningen", "høj", "jord", "have", "gadekær", "skole", "bjergs", "port", "have", "dal", "dam", "engs", "skovs", "holms", "jords", "højs", "søen", "stranden", "parkens", "broen", "enges", "vangs", "dals", "havens", "dammen", "enget", "ly", "byens", "kærrets", "lundens", "have", "plads", "park", "engs", "torvet", "skovs", "holms", "vejene"]

function explainEvent(str) {
  
}

const string = 'Hovedstadens BeredskabFørstemelding: Brandalarm Station: HvidovreSat, 29 Jul 2023 03:33:37 GMT'
console.log(explainEvent(string));

function getAddress(text, suffixList) {
  let word;
  const result = [];
  const regexSuffixes = new RegExp(`(${suffixList.join('|')})`, 'gi');
  const regexInvalidText = /#\d+|\b\d{5,}\b/g;

  let match;
  

  
  while ((match = regexSuffixes.exec(text)) !== null) {
    const startIndex =  match.index;
    let endIndex = text.indexOf(' ', startIndex + match[0].length);   
    console.log(endIndex)

for (let i = text.length - 1; i >= 0; i--) {
  if (text[i] === ' ') {
    word = text.substring(i + 1);
    if (suffixList.some(suffix => word.includes(suffix))) {
      console.log(word);
      // Remove the break statement if you want to print all words containing a suffix from the list
      break;
    }
  }
}  
    if (endIndex === -1) endIndex = text.length;

    const suffix = match[0];
    let words = text.substring(startIndex - 5, endIndex).trim();
    const remainingText = text.substring(endIndex).trim();

    if (!regexInvalidText.test(remainingText)) {
      const remainingTextWithoutNumbers = remainingText.replace(regexInvalidText, '').trim();
      result.push({ word, suffix, text: remainingTextWithoutNumbers });
    }
  }

  return word;
}

function removeDateAndPOCSAG(inputString) {
    // The regular expression to match the date and "POCSAG-4 ALPHA 1200"
    const regex = /^(\d{2}:\d{2}:\d{2} \d{2}-\d{2}-\d{3} POCSAG-4 ALPHA 1200 )/;
  
    // Replace the matched part with an empty string
    const result = inputString.replace(regex, '');
  
    // Find the index of the next space after "POCSAG-4 ALPHA 1200"
    const nextSpaceIndex = result.indexOf(' ', result.indexOf('POCSAG-4 ALPHA 1200') + 1);
  
    // Remove everything after the next space until the address begins
    if (nextSpaceIndex !== -1) {
      return result.substring(0, nextSpaceIndex);
    }
  
    return result;
  }
  
  function getTime(inputString) {
    return inputString.substring(0, 8);
  }
  
  function FindCity(text,cityList) {
 // Reverse the entire text
 const reversedText = text.split('').reverse().join('');

 // Extract the part until the first space (or entire string if no space found)
 const firstSpaceIndex = reversedText.indexOf(' ');
 const extractedPart = firstSpaceIndex !== -1 ? reversedText.substring(0, firstSpaceIndex) : reversedText;

 // Remove numbers and '#' from the extracted part
 const cleanedExtractedPart = extractedPart.replace(/[0-9#\s]/g, '');

 // Reverse the cleaned extracted part back to its original form
 const originalExtractedPart = cleanedExtractedPart.split('').reverse().join('');

 

 // Check if the extracted part is in the allowed words list
 if (cityList.some(item => originalExtractedPart.includes(item))) {
   return originalExtractedPart;
 } else {
   return "Kendes ikke";
 }
  }
  

  function removeStringsFromArray(text, stringsToRemove) {
    const escapedStrings = stringsToRemove.map(str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    const regex = new RegExp(escapedStrings.join('|'), 'gi');
    return text.replace(regex, '');
  }
  
  const eventList = [
    "0",
    "1",
    "2",
     "3",
     "4",
     "5",
     "6",
     "7",
     "8",
     "9",
     ":",
     "- ",
     "-",
     " -- ",
     " --",
     "-- ",
     "-- -",
     "@",
     "POCSAG-4 ALPHA 1200",
     "POCSAG-",
     "POCSAG",
     "ALPHA",
     "+",
     
  ]

  const timeList = [
    ""
  ]

  const addressList = [
    ""
  ]
  
  const notAllowedList = [
    "prøve",
    "NUMERIC",
    "TONE",
    "ONLY",
    "Dagens",
    "Radioprøve",
    "test",
    "testmail",
    "Testmail",
    "Test",
    "<html",
  ]


const MailinatorComponent = () => {
  const [emailIds, setEmailIds] = useState([]);
  const [emailDetails, setEmailDetails] = useState([]);
const key = "?token=684414b5a5724600b1c57469091a9296"
  // useEffect(() => {
  //   // Fetch email IDs from the first API
  //   fetch(`https://mailinator.com/api/v2/domains/team625055.testinator.com/inboxes/*${key}`)
  //     .then(response => response.json())
  //     .then(data => setEmailIds(data.msgs))
  //     .catch(error => console.error('Error fetching email IDs:', error));
      
  // }, []);
// console.log(emailIds);
  useEffect(() => {
    // Fetch email details for each email ID
    emailIds?.forEach(emailId => {
      fetch(`https://mailinator.com/api/v2/domains/team625055.testinator.com/messages/${emailId.id}${key}`)
        .then(response => response.json())
        .then(data => {
          // Append email details to the existing state
          setEmailDetails(prevDetails => [...prevDetails, data]);
          // console.log(data);
        })
        .catch(error => console.error('Error fetching email details:', error));
    });
  }, [emailIds]);
// console.log("lll");
// console.log(emailDetails);
  return (
    <div>
      {emailDetails.length > 0 ? (
        <div>
          {emailDetails.map((email,index) => (
!notAllowedList.some(item => email.parts[0].body.includes(item)) ? (
    <EventCards 
    key={emailDetails.id}
      event={removeStringsFromArray(email.parts[0].body, eventList)}
      time={getTime(email.parts[0].body)}
      city={getAddress(email.parts[0].body, suffixList)}
    />
) : index < emailDetails.length - 1 ? <p></p> : <p>Ingen alarmer endnu</p>
  
          
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default MailinatorComponent;
