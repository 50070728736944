import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EventCards from './Components/EventCards/EventCards';
import CityBounds from './Components/GeoCode/GeoCode';
const cityList = [
  "Aabenraa", "Aabybro", "Aakirkeby", "Aalborg", "Aalborg SV", "Aalborg SØ", "Aalborg Øst", "Aalestrup",
  "Aarhus C", "Aarhus N", "Aarhus V", "Aars", "Aarup", "Agedrup", "Agerbæk", "Albertslund", "Allerød",
  "Allinge", "Allingåbro", "Ansager", "Arden", "Asnæs", "Assens", "Augustenborg", "Aulum", "Auning",
  "Bagsværd", "Ballerup", "Beder", "Billund", "Bindslev", "Birkerød", "Bjerringbro", "Bjæverskov", "Blokhus",
  "Blåvand", "Bogense", "Bording", "Borup", "Brabrand", "Bramming", "Brande", "Bredebro", "Bredsten",
  "Broager", "Broby", "Brovst", "Brædstrup", "Brøndby", "Brøndby Strand", "Brønderslev", "Brønshøj", "Brørup",
  "Børkop", "Charlottenlund", "Christiansfeld", "Dianalund", "Dragør", "Dronninglund", "Dyssegård", "Ebeltoft",
  "Egtved", "Egå", "Ejby", "Engesvang", "Esbjerg", "Esbjerg N", "Esbjerg V", "Esbjerg Ø", "Espergærde",
  "Faaborg", "Fanø", "Farsø", "Farum", "Faxe", "Faxe Ladeplads", "Fjerritslev", "Fredensborg", "Frederiksberg",
  "Frederikshavn", "Frederikssund", "Frederiksværk", "Fuglebjerg", "Fårevejle", "Fårvang", "Gadstrup", "Galten",
  "Gentofte", "Gilleleje", "Gistrup", "Give", "Gjerlev J", "Glamsbjerg", "Glesborg", "Glostrup", "Glumsø",
  "Gram", "Grenaa", "Greve", "Grindsted", "Gråsten", "Græsted", "Gudhjem", "Gørlev", "Haarby", "Haderslev",
  "Hadsten", "Hadsund", "Hals", "Hammel", "Hanstholm", "Hasle", "Haslev", "Hasselager", "Havdrup", "Hedehusene",
  "Hedensted", "Hellerup", "Helsinge", "Helsingør", "Herfølge", "Herlev", "Herning", "Hesselager", "Hillerød",
  "Hinnerup", "Hirtshals", "Hjallerup", "Hjortshøj", "Hjørring", "Hobro", "Holbæk", "Holmegaard", "Holstebro",
  "Holsted", "Holte", "Hornbæk", "Hornslet", "Horsens", "Humble", "Humlebæk", "Hundested", "Hurup Thy", "Hvalsø",
  "Hvide Sande", "Hvidovre", "Hårlev", "Højbjerg", "Højby", "Høje Taastrup", "Højslev", "Høng", "Hørning",
  "Hørsholm", "Hørve", "Ikast", "Ishøj", "Jelling", "Juelsminde", "Jyderup", "Jyllinge", "Jægerspris",
  "Kalundborg", "Karise", "Karlslunde", "Karup J", "Kastrup", "Kerteminde", "Kibæk", "Kirke Hyllinge", "Kirke Såby",
  "Kjellerup", "Klampenborg", "Klarup", "Knebel", "Kokkedal", "Kolding", "Kolind", "Kongens Lyngby", "Korsør",
  "Kruså", "København", "København C", "Køge", "Langeskov", "Langå", "Lejre", "Lemvig", "Lille Skensved",
  "Lundby", "Lynge", "Lystrup", "Læsø", "Løgstør", "Løgumkloster", "Løkken", "Løsning", "Malling", "Mariager",
  "Maribo", "Marstal", "Middelfart", "Munkebo", "Måløv", "Møldrup", "Mørke", "Nakskov", "Nexø", "Nibe",
  "Nivå", "Nordborg", "Nordhavn", "Nyborg", "Nykøbing F", "Nykøbing M", "Nykøbing Sj", "Nysted", "Nærum",
  "Næstved", "Nørager", "Nørre Aaby", "Nørre Alslev", "Nørre Nebel", "Nørresundby", "Odder", "Odense", "Odense M",
  "Odense N", "Odense NV", "Odense NØ", "Odense S", "Odense SV", "Odense SØ", "Odense V", "Oksbøl",
  "Otterup", "Padborg", "Pandrup", "Præstø", "Randers C", "Randers NV", "Randers NØ", "Randers SV", "Randers SØ",
  "Regstrup", "Ribe", "Ringe", "Ringkøbing", "Ringsted", "Risskov", "Risskov Ø", "Roskilde", "Roslev", "Rudkøbing",
  "Rungsted Kyst", "Ry", "Ryomgård", "Rødby", "Rødding", "Rødekro", "Rødovre", "Rønde", "Rønne", "Rønnede",
  "Sabro", "Sakskøbing", "Samsø", "Silkeborg", "Sindal", "Skagen", "Skals", "Skanderborg", "Skibby", "Skive",
  "Skjern", "Skovlunde", "Skælskør", "Skærbæk", "Skævinge", "Skødstrup", "Skørping", "Slagelse", "Slangerup",
  "Smørum", "Snedsted", "Solbjerg", "Solrød Strand", "Sorø", "Spentrup", "Spjald", "Spøttrup", "Stege", "Stenlille",
  "Stenløse", "Store Heddinge", "Storvorde", "Struer", "Stubbekøbing", "Støvring", "Suldrup", "Sunds", "Svendborg",
  "Svenstrup J", "Svinninge", "Sydals", "Sæby", "Søborg", "Sønder Omme", "Sønderborg", "Søndersø", "Taastrup",
  "Tappernøje", "Tarm", "Terndrup", "Them", "Thisted", "Thyborøn", "Tilst", "Tinglev", "Tjele", "Toftlund",
  "Tommerup", "Tranbjerg J", "Tranekær", "Trige", "Trustrup", "Tune", "Tølløse", "Tønder", "Tørring", "Ulfborg",
  "Ullerslev", "Vadum", "Valby", "Vallensbæk", "Vallensbæk Strand", "Vamdrup", "Vanløse", "Varde", "Vedbæk", "Vejby",
  "Vejen", "Vejle", "Vejle Øst", "Vemmelev", "Vesløs", "Vestbjerg", "Vester Skerninge", "Viborg", "Viby J",
  "Viby Sjælland", "Videbæk", "Vig", "Vildbjerg", "Vinderup", "Vinterbyøster", "Virum", "Vissenbjerg", "Vodskov",
  "Vojens", "Vordingborg", "Vrå", "Væggerløse", "Værløse", "Ærøskøbing", "Åbyhøj", "Ålbæk", "Ålsgårde", "Årslev",
  "Ølgod", "Ølsted", "Ølstykke", "Ørbæk", "Ørsted", "Østbirk", "Øster Assels"
];

const explainList = [
  {
    udtryk: "brand",
    synonymer: ['brand', 'bra'],
    tekst: 'En brand er sket'
  },
]



const suffixList = ["vej", "gade", "vænge", "allé", "boulevard", "plads", "sti", "stræde", "terasse", "terrasse", "vejlede", "gården", "aveny", "stien", "søvej", "fjord", "øster", "vest", "nord", "syd", "promenade", "mole", "esplanade", "torv", "mark", "løkke", "holmen", "skov", "skoven", "hegnet", "engen", "eng", "vang", "kærret", "kær", "bjerg", "bjergene", "bjergvej", "bakke", "bakken", "alle", "alleen", "gård", "torvet", "holme", "lund", "vænget", "parken", "park", "by", "byen", "pladsen", "grunden", "grønningen", "høj", "jord", "have", "gadekær", "skole", "bjergs", "port", "have", "dal", "dam", "engs", "skovs", "holms", "jords", "højs", "søen", "stranden", "parkens", "broen", "enges", "vangs", "dals", "havens", "dammen", "enget", "ly", "byens", "kærrets", "lundens", "have", "plads", "park", "engs", "torvet", "skovs", "holms", "vejene", "gade", "vænge", "allé", "boulevard", "plads", "sti", "stræde", "terasse", "terrasse", "vejlede", "gården", "aveny", "stien", "søvej", "fjord", "øster", "vest", "nord", "syd", "promenade", "mole", "esplanade", "torv", "mark", "løkke", "holmen", "skov", "skoven", "hegnet", "engen", "eng", "vang", "kærret", "kær", "bjerg", "bjergene", "bjergvej", "bakke", "bakken", "alle", "alleen", "gård", "torvet", "holme", "lund", "vænget", "parken", "park", "by", "byen", "pladsen", "grunden", "grønningen", "høj", "jord", "have", "gadekær", "skole", "bjergs", "port", "have", "dal", "dam", "engs", "skovs", "holms", "jords", "højs", "søen", "stranden", "parkens", "broen", "enges", "vangs", "dals", "havens", "dammen", "enget", "ly", "byens", "kærrets", "lundens", "have", "plads", "park", "engs", "torvet", "skovs", "holms", "vejene"]

function explainEvent(str) {
  
}

const string = 'Hovedstadens BeredskabFørstemelding: Brandalarm Station: HvidovreSat, 29 Jul 2023 03:33:37 GMT'
console.log(explainEvent(string));

function getAddress(text, suffixList) {
  let word;
  const result = [];
  const regexSuffixes = new RegExp(`(${suffixList.join('|')})`, 'gi');
  const regexInvalidText = /#\d+|\b\d{5,}\b/g;

  let match;
  

  
  while ((match = regexSuffixes.exec(text)) !== null) {
    const startIndex =  match.index;
    let endIndex = text.indexOf(' ', startIndex + match[0].length);   
    console.log(endIndex)

for (let i = text.length - 1; i >= 0; i--) {
  if (text[i] === ' ') {
    word = text.substring(i + 1);
    if (suffixList.some(suffix => word.includes(suffix))) {
      console.log(word);
      // Remove the break statement if you want to print all words containing a suffix from the list
      break;
    }
  }
}  
    if (endIndex === -1) endIndex = text.length;

    const suffix = match[0];
    let words = text.substring(startIndex - 5, endIndex).trim();
    const remainingText = text.substring(endIndex).trim();

    if (!regexInvalidText.test(remainingText)) {
      const remainingTextWithoutNumbers = remainingText.replace(regexInvalidText, '').trim();
      result.push({ word, suffix, text: remainingTextWithoutNumbers });
    }
  }

  return word;
}

function removeDateAndPOCSAG(inputString) {
    // The regular expression to match the date and "POCSAG-4 ALPHA 1200"
    const regex = /^(\d{2}:\d{2}:\d{2} \d{2}-\d{2}-\d{3} POCSAG-4 ALPHA 1200 )/;
  
    // Replace the matched part with an empty string
    const result = inputString.replace(regex, '');
  
    // Find the index of the next space after "POCSAG-4 ALPHA 1200"
    const nextSpaceIndex = result.indexOf(' ', result.indexOf('POCSAG-4 ALPHA 1200') + 1);
  
    // Remove everything after the next space until the address begins
    if (nextSpaceIndex !== -1) {
      return result.substring(0, nextSpaceIndex);
    }
  
    return result;
  }
  
  function getTime(inputString) {
    return inputString.substring(0, 8);
  }
  
  function FindCity(text,cityList) {
 // Reverse the entire text
 const reversedText = text.split('').reverse().join('');

 // Extract the part until the first space (or entire string if no space found)
 const firstSpaceIndex = reversedText.indexOf(' ');
 const extractedPart = firstSpaceIndex !== -1 ? reversedText.substring(0, firstSpaceIndex) : reversedText;

 // Remove numbers and '#' from the extracted part
 const cleanedExtractedPart = extractedPart.replace(/[0-9#\s]/g, '');

 // Reverse the cleaned extracted part back to its original form
 const originalExtractedPart = cleanedExtractedPart.split('').reverse().join('');

 

 // Check if the extracted part is in the allowed words list
 if (cityList.some(item => originalExtractedPart.includes(item))) {
   return originalExtractedPart;
 } else {
   return "Kendes ikke";
 }
  }
  

  function removeStringsFromArray(text, stringsToRemove) {
    const escapedStrings = stringsToRemove.map(str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    const regex = new RegExp(escapedStrings.join('|'), 'gi');
    return text.replace(regex, '');
  }
  
  const eventList = [
    "0",
    "1",
    "2",
     "3",
     "4",
     "5",
     "6",
     "7",
     "8",
     "9",
     ":",
     "- ",
     "-",
     " -- ",
     " --",
     "-- ",
     "-- -",
     "@",
     "POCSAG-4 ALPHA 1200",
     "POCSAG-",
     "POCSAG",
     "ALPHA",
     "+",
     
  ]

  const timeList = [
    ""
  ]

  const addressList = [
    ""
  ]
  
  const notAllowedList = [
    "prøve",
    "NUMERIC",
    "TONE",
    "ONLY",
    "Dagens",
    "Radioprøve",
    "test",
    "testmail",
    "Testmail",
    "Test",
    "<html",
  ]

const MailSlurpEmailList = (props) => {
const [emails, setEmails] = useState([]);

  useEffect(() => {
    const fetchEmails = async () => {
      const apiUrl = `https://api.mailslurp.com/emails?inboxId=b5f89f84-4079-4912-b247-61fc34588a5d&page=0&size=10&sort=DESC&since=${props.date}T00:00:00Z&before=${props.date}T23:00:00Z`;
      const apiKey = '92d393766c7ce4a3d76997fca94a956b9f6c168ce58ad20798763f504b369b8c'; // Replace with your MailSlurp API key

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
          },
        });

        setEmails(response.data.content);
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    };

    fetchEmails();
  }, [props.date]);
console.log(emails);
  return (
    <div>
      {emails.length > 0 ? (
        <div>
          {emails.map((email,index) => (
!notAllowedList.some(item => email.bodyExcerpt.includes(item)) ? (
    <EventCards 
    key={emails.id}
      event={removeStringsFromArray(email.bodyExcerpt, eventList)}
      time={getTime(email.bodyExcerpt)}
      city={getAddress(email.bodyExcerpt, suffixList)}
    />
) : index < emails.length - 1 ? <p></p> : <p>Ingen alarmer endnu</p>
  
          
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default MailSlurpEmailList;
