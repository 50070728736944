import styled from "styled-components";

export const HeliCardTwoStyle = styled.div`
border: solid 1px black;
border-radius: 20px;
margin-right: 1vw;
margin-top: 1vh;
padding-left: 1vw;
display: grid;
grid-template-columns: repeat(5, 1fr);
 .title{
    color: gray;
 }

 .ball{
    margin-top: 1vh;
    height: 3.5vh;
    width: 2vw;
    background-color: green;
    border-radius: 20px;
 }
`